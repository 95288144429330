import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';

jQueryBridget('masonry', Masonry, $);
jQueryBridget('imagesLoaded', imagesLoaded, $);

var grid  = $('.grid');

var cardDeck = $('.card-deck');

$('.nav-item').on('click', function() {
  setTimeout(function() {
  cardDeck.masonry({
    itemSelector: '.card',
    fitWidth: true,
    initLayout: true
  });
},200 )
});


if (grid.length) {

    /** Init masonry */

    var screenWidth = window.innerWidth;

    var isMobile = screenWidth < 768;

  var gridItemSelector = '.grid-item';

  grid.masonry({
    itemSelector:       gridItemSelector,
    fitWidth: true,
    initLayout:         false
  });


  var grid_items = grid.find(gridItemSelector);

  grid
  .imagesLoaded(
    function() {
      /** Display images now (masonry need to see them to calculate sizes / coords...) */
      grid_items.show();
      /** Refresh masonry layout */
      grid.masonry('layout');
  });

}

$( "#menu-nos-produits, #menu-produits").on( "click", function (e) {


  setTimeout(function() {   

    // trick to mask menu during ugly transition reloading 
    $('#content-category-menu').find('.parent a').css('color','#fff');
    $('#content-category-menu').masonry({
        itemSelector: '.parent',
        columnWidth: $('#content-category-menu').width()
    }).masonry('reloadItems');

    // remove not wanted last link (tous les produits)
    $('#content-category-menu').find(".parent").last().remove();

  },300 )

  // display when reload is done
  setTimeout(function() {   
    $('#content-category-menu').find('.parent a').css('color','#1f2329');
  },400 )
   
} )


var cache = {};

$(document).ready(function() {

    $(".nav-category").each(function() {
        var url = $(this).attr('data-url');
        if (url && !cache[url]) {
            $.get(url, function(data) {
                cache[url] = data;
            });
        }
    });

    $(".nav-category").click(function() {

        $(this).parent().find('.card.active').removeClass('active');
        $(this).parent().find('.card').addClass('inactive');
        $(this).find('.card').addClass('active');     
        $(this).find('.card').removeClass('inactive');     
        updateContent(cache[$(this).attr('data-url')]);
    });
});



function updateContent(data) {
    var content = $('#content-category-menu');
    
    content.html($('#content-category-menu', data).html());

    // trick to mask menu during ugly transition reloading 
    content.find('.parent a').css('color','#fff');
    content.masonry('reloadItems').masonry({
        itemSelector: '.parent',
    });

}
"use strict";

// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it



import 'bootstrap';
import 'datatables.net';
import 'owl.carousel';
import 'scrollreveal';
import 'slick-carousel';
import  './jquery-yacal-custom';
var pap = pap || {};


jQuery(function() {
    


  // trick to prevent close div when scroll ..
  $('body').on('click', '.mCSB_draggerContainer', function (event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    });


    /**
     * Form validation class before first focus
     */
    pap.input.trigger();
  pap.logoPartenaireCarousel.init();
  pap.homeCarousel.init();
  pap.themeCarousel.init();
  pap.customScript.init();
  pap.customCalendar.init();
  pap.dataTable.init();
  pap.counter.init();
  pap.dileveryAdress.init();
  pap.customFilters.init();
  pap.basketChecklist.init();
  pap.listRubriqueAccordion.init();
  pap.accordionSidemenu.init();
  pap.scrollReveal.init();
  pap.nav_dropdown.init();
  pap.displayTitle.init();
  pap.productCarousel.init();
  pap.actuCarousel.init();
  pap.sujetCarousel.init();
  pap.sliderHeader.init();
  pap.sliderCategory.init();
 
});

pap.cadencier = {
    init: function () {

        $('.add_to_cadencier').on('click', function(event) {
            event.preventDefault();
            var productId = $(this).data('product-id');
            pap.cadencier.addProduct(productId);
            $(this).html('<i class="fa fa-spinner fa-spin" ></i>');
        });
        $(document).ready(function() {
            $('.remove_from_cadencier').on('click', function(event) {
                event.preventDefault();
                var productId = $(this).data('product-id');
                var buttonId = $(this).attr('id');
                let productName = $(this).closest('.basket-item').find('.cart-item-title').text();
                
                pap.modals.add('confirm', 'removeProduct', {
                    id: 'modal-confirmation',
                    relatedTarget: buttonId,
                    title: ' ',
                    content: 'Souhaitez-vous confirmer la suppression du <br> produit <strong>' + productName + '</strong> de votre cadencier ?',
                    actions: [
                        {
                            class: 'btn-valider-remove-from-cadencier',
                            text: 'Confirmer',
                            icon: '',
                            attr: [
                                {
                                    name: 'onclick',
                                    value: `pap.cadencier.removeProduct('${productId}');`
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                            ],
                        }
                    ]
                });
                $('#modalConfirmation').modal('show', '#' + buttonId);
            });
        });

    },
    addProduct: function (productId) {
        let ajax = pap.ajax;
        ajax.url = pap.routing.front_cadencier_add;
        ajax.data = {
            'productId': productId,
        };
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.execute();
    },
    removeProduct: function (productId) {
        let ajax = pap.ajax;
        ajax.url = pap.routing.front_cadencier_remove;
        ajax.data = {
            'productId': productId,
        };
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.execute();
    }
  }

pap.dematWorkflow = {
    trigger: function () {

        $('.modal.show').modal('hide');
        setTimeout(function () {
            $('#modalDematWorkflow').modal('show');
        }, 2000);


        $('#modalDematWorkflow').on('hidden.bs.modal', function (e) {

            let callAjax = pap.ajax;
            callAjax.url = pap.routing.dematWorkflowClose;
            callAjax.method = 'POST';
            callAjax.dataType = 'json';   
            callAjax.execute();
      
          })
      
        $('#modalDematWorkflow :checkbox').change(function() {
            pap.dematWorkflow.generateForm();        
        });

        
    },
    generateForm: function () {
        let callAjax = pap.ajax;

        callAjax.url = pap.routing.dematWorkflowRender;
        callAjax.method = 'GET';
        callAjax.dataType = 'html';
        callAjax.onSuccess = function (data) {
            $('#demat-invoice-form').html(data);
            pap.dematWorkflow.processForm();
        };
        callAjax.execute();
    },
    processForm: function () {
        var customersSkipped = [];
        var customers = [];

        $('#modalDematWorkflow input[type=checkbox]').each(function() {
            if(!this.checked) {
                customersSkipped.push($(this).data( "customer" ));
            }
            customers.push($(this).data( "customer" ));
        });

        if(customersSkipped.length < 1) {
            return;
        }

        if (customers.length > customersSkipped.length) {     
            customersSkipped.forEach(customerSkipped => {
                $('#demat-invoice-form').find('#' + customerSkipped).remove();
            });   
        } else {
            $('#demat-invoice-form').html("");
         
        }
    }
}

pap.input = {
    trigger: function (parent) {
        if (typeof parent === "undefined") {
            parent = $(document);
        }
        $(parent).find('input').each(function(){
            $(this).addClass('first-load');
            $(this).focusout(function(){
                $(this).removeClass('first-load');
            });
        });
    }
};
pap.accordionSidemenu = {
  init: function () {
    var $ulGlobal = $(".ul-global");

    if ($ulGlobal.length > 0) {
      $ulGlobal.click(function (event) {
        event.preventDefault();
      });

      $(".category ").click(function (event) {
        location.href = $(this).attr('data-option');
      });
      if($(".selectedCategory").length) {
          var $a = $(".selectedCategory").parent("a");

          $a.parent("li").children("ul").slideDown(200,
              function() {
                  $a.attr("data-option", "on");
              });

          if(!$a.hasClass('a-global')) {
              $(".selectedCategory").parents("li").each(function() {
                  $(this).parent("ul").slideDown(200,
                      function() {
                          $(this).parent("li").children("a").attr("data-option", "on");
                      })
              })
          }
      }


      $ulGlobal.vmenuModule({
        Speed: 200,
        autostart: false,
        autohide: true
      });
    }
  }
}

pap.dileveryAdress = {
  init: function () {
    var $adress = $('.block-adresse'),
      $adressItem = $adress.find(".block-adresse__items__item"),
      $btn = $adress.find('.btn');

    if ($adress.length > 0) {
      $btn.click(function (e) {
        e.preventDefault();

        $(this).closest('.block-adresse__items').find('.block-adresse__items__item').removeClass('selected');

        $(this).closest($adressItem).addClass("selected");
      });
    }
  }
};

pap.basketChecklist = {
  init: function () {
    var $blockBasketList = $(".block-basket-list"),
        $checkAll = $blockBasketList.find("#select-all"),
        $checkItems = $(".basket-item input[type='checkbox']");

    if ($blockBasketList.length > 0) {
      $checkAll.change(function (e) {
        if (e.target.checked) {
          $checkItems.each(function (idx, item) {
            $(item).prop("checked", true);
          });
        } else {
          $checkItems.each(function (idx, item) {
            $(item).prop("checked", false);
          });
        }
      });

      $checkItems.change(function (e) {
        var checked = $(this).is(":checked");

        if (!checked) {
          $checkAll.prop("checked", false);
        } else {
          $checkItems.not($(this)).each(function (idx, item) {
            if ($(item).is(":checked") === false) {
              checked = false;
            }
          });
          if (checked) {
            $checkAll.prop("checked", true);
          }
        }
      });
    }
  }
};

pap.counter = {
  init: function () {
    var $counterContent = $('.counter__content');

        $(document).on('change', '.counter__content input', function (e) {
            let originalQuantity = parseFloat($(this).val());
            let quantity = originalQuantity;
            let max = parseFloat($(this).attr("max"));
            quantity = Math.abs(quantity);

            // e.preventDefault();
            let counterDom = $(this).parent($counterContent);
            counterDom.removeClass('border-alert');
            counterDom.find('.plus').removeClass('disabled-link');
            counterDom.find('.minus').removeClass('disabled-link');

            if (quantity >= max) {
                if (quantity > max) {
                    let atomicUnit = parseFloat($(this).attr("data-step"));
                    $(this).parent($counterContent).parent().find('.maxQuantity').removeClass('d-none');
                    let factor = parseInt(max / atomicUnit);
                    quantity = $(this).attr("data-step") * factor;
                    if (quantity < 0) {
                        quantity = 0;
                    }
                }
                $(this).siblings('.plus').addClass('disabled-link');
            }

            if (quantity <= parseFloat($(this).attr("min"))) {
                $(this).siblings('.minus').addClass('disabled-link');
            }
            if (originalQuantity !== quantity) {
                $(this).prop('value', quantity);
            }
        });
      $(document).on('click', '.counter__content .minus', function (e) {
        e.preventDefault();
        var $this = $(this),
          $input = $this.siblings('input');
          $this.parent($counterContent).removeClass('border-alert');

          let step = parseFloat($input.attr('data-step'));
        if ($input.val() > 0) {
            let value = parseFloat($input.val());
            let newValue = Math.round((value - step) * 100) / 100;
            $input.val(newValue);
        }

        if ($input.val() < parseFloat($input.attr("min"))) {
            $input.val($input.attr("min"));
        }

          $input.trigger('change');
      });

        $(document).on('click', '.counter__content .plus', function (e) {
          e.preventDefault();
        var $this = $(this),
          $input = $this.siblings('input');
        let step = parseFloat($input.attr('data-step'));
        let value = parseFloat($input.val());
        let newValue = Math.round((value + step) * 100) / 100;
        $input.val(newValue);


        if ($input.attr("max") < $input.val()) {
          $this.parent($counterContent).addClass('border-alert');
          $this.addClass('disabled-link');
        }

        $input.trigger('change');
        e.preventDefault();
       // e.stopPropagation();
      });
  }
};

pap.customCalendar = {
    init: function (initOptions) {
    var initOptions = (typeof initOptions !== 'undefined') ? initOptions : [];
    var nearMonths, months, weekdays, dayClass;
    var eventItems = [];
    if ($('html').attr('lang') === "fr") {
      nearMonths = 0;
      weekdays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
      months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
      dayClass = "animated slideInLeft";
    } else {
      nearMonths = 1;
      weekdays = ['Do', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      months = ['January', 'February', 'March', 'April', 'May', 'Jun', 'July', 'August', 'September', 'October', 'November', 'December'];
      dayClass = "animated slideInLeft";
    }
    

    $('[id^="calendar-"]').each(function () {
      eventItems.push({
        "id": $(this).data('id'),
        "date": $(this).data('date'),
        "title": $(this).data('title'),
        "dureeCoupure": $(this).data('dureecoupure'),
        "dureeDegradation": $(this).data('dureedegradation'),
        "maintenanceId": $(this).data('maintenanceid'),
        "maintenance": $(this).data('maintenance'),
        "startingDate": $(this).data('startingdate'),
        "endingDate": $(this).data('endingdate'),
        "other": $(this).data('other')
      });
    });

    var defaultOptions = {
        nearMonths: ($(window).width() > 768) ? 0.5 : nearMonths,
        showWeekdays: true,
        isActive: function(date)
        {
            return false;
        },
        tpl: {
            day: '#content#<p #data-id# class="day d#weekday#  #weekend# #today# #selected# #active# #event# #dayclass#" ><span>#day#</span></p>',
        },
        EventsItems: {
            "events": eventItems
        },
        i18n: {
            weekdays: weekdays,
            months: months
        },
        dayClass: dayClass

    };

    var options = $.extend(true, {}, defaultOptions, initOptions);

    $('.calendar').yacal(options);

    $(document).on('click', '.event', function () {
      var target = $(this).attr('id');
      var offset = $(this).offset(),
        Top = offset.top - $(document).scrollTop();
      $('.event_container').addClass('hidden zoomOut').removeClass('zoomIn');

      if ($(window).width() > 1024) {
        if (Top < 500) {
          $(this).parent().find('.event_container').attr('data-id', target).css({
            'top': '65px'
          }).removeClass('hidden zoomOut').addClass('zoomIn top');
        } else {
          $('.event_container').addClass('hidden zoomOut').removeClass('zoomIn');
          $('.content__inner').addClass('hidden');
          $(this).parent().find('.event_container').attr('data-id', target).css({
            'bottom': '50px'
          }).removeClass('hidden zoomOut').addClass('zoomIn bottom');
        }
      } else {
        $(this).parent().find('.event_container').attr('data-id', target).removeClass('hidden zoomOut zoomIn').addClass('zoomIn');
      }
    });

    $(document).on('click', '.event_container .icon-angle-down', function () {
      if ($(this).siblings('.content__inner').hasClass('hidden')) {
        $(this).siblings('.content__inner')
          .removeClass('hidden');
      } else {
        $(this).siblings('.content__inner').addClass('hidden');
      }
    });

    $(document).on('click', '.event_container .icon-close', function () {
      $(this).parent().addClass('hidden zoomOut').removeClass('zoomIn').removeAttr('style');
      $(this).siblings('.content__inner').addClass('hidden');
    });


    if ($(window).width() < 768) {
      $('.event_container .icon-angle-down').addClass('hidden');
      $('.event_container .content__inner').removeClass('hidden');
    } else {
      $('.event_container .content__inner').addClass('hidden');
    }
    $('.calendar').bind("DOMSubtreeModified", function () {
      if ($(window).width() < 768) {
        $('.event_container .icon-angle-down').addClass('hidden');
        $('.event_container .content__inner').removeClass('hidden');
      } else {
        $('.event_container .content__inner').addClass('hidden');
      }
    });

    },
    renderLegend: function (dateDeliveryRounds) {

      if($('#cart_deliveryRoundType option').length < 1) return;

      // Recuperation des descriptions des tournées
      var roundsAvailable = [];
      $('#cart_deliveryRoundType option').each((key, value )=> {
        roundsAvailable[value.value] = value.text;
    });
      
      var ul = document.createElement("ul");
      ul.setAttribute("class", 'calendar-rounds-legend-list'); 

      // On boucle sur les tournées dispos sur le calendrier
  
      [... new Set(Object.entries(dateDeliveryRounds).map(x => x[1]))].forEach(deliveryRound => {
          var li = document.createElement("li");
          li.setAttribute("style", 'margin-bottom:20px; list-style: none; min-width: 162px');
          if (deliveryRound == 'fsg') {
            li.setAttribute("id", "1" );
          }
          if (deliveryRound == 'fs') {
            li.setAttribute("id", "2" );
          }
          if (deliveryRound == 'sg') {
            li.setAttribute("id", "3" );
          }
          if (deliveryRound == 'fg') {
            li.setAttribute("id", "4" );
          }
          if (deliveryRound == 'sec') {
            li.setAttribute("id", "5" );
          }
          if (deliveryRound == 'fra') {
            li.setAttribute("id", "6" );
          }
          if (deliveryRound == 'gel') {
            li.setAttribute("id", "7" );
          }
          if (deliveryRound == 'multiple') {
            li.setAttribute("id", "8" );
          }

          // li.setAttribute("id", i++ );
          var span = document.createElement("span");  
          span.setAttribute("style", 'margin-left: -40px');                 
          // Tournée multiple
          if (deliveryRound == 'multiple') {
            span.setAttribute("class", 'picto_multiple day'); 
            
            li.appendChild(span);
            li.appendChild(document.createTextNode('Choix multiple'));          
          } else {
            span.setAttribute("class", `picto_${deliveryRound} day`); 
            li.appendChild(span);
            li.appendChild(document.createTextNode(roundsAvailable[deliveryRound]));
          }

          ul.appendChild(li);
          
        });

        // Construction de la légende
        var legend = document.createElement("div");
        legend.setAttribute("class", 'calendar-rounds-legend col-12 order-1 p-0'); 
        legend.appendChild(ul);
        

        // Ajout de la légende sur le calendrier
        // $('.calendar').append(legend);
        $('.block-calendar').append(legend);
        $('.calendar-legend').append(legend);
        //classement li par id
        $(".calendar-rounds-legend-list li").sort(function(a, b) {
          return parseInt(a.id) - parseInt(b.id);
        }).each(function() {
          var elem = $(this);
          elem.remove();
          $(elem).appendTo(".calendar-rounds-legend-list");
        });




    },
    render: function (dateDeliveryRounds, maxDispo) {
        var maxDispo = (typeof maxDispo !== 'undefined') ? maxDispo : 12;
        let dateAvailable = [];
        let delRounds = [];
   
        Object.keys(dateDeliveryRounds).forEach(function(value) {
            let d = new Date(value);
            d.setHours(d.getHours() + (d.getTimezoneOffset() + 120) /60 );
          dateAvailable[d.toLocaleDateString('fr')] = d;
          delRounds[d.toLocaleDateString('fr')] = dateDeliveryRounds[value];
        });


        let Options = {
            isActive: function(date)
            {
                if (typeof dateAvailable[date.toLocaleDateString('fr')] !== "undefined")
                {
                    return true;
                }

                return false;
            },
            dayClass: function(date)
            {
              if (typeof dateAvailable[date.toLocaleDateString('fr')] !== "undefined")
              {
                  return `picto_${delRounds[date.toLocaleDateString('fr')]}`;
              }
            },
        };

                  //generation de la légende du calendrier

                 

      $('.calendar').bind("DOMNodeInserted", function () {
          let calendarContainer = $(this);


          let dateDelivery = new Date(calendarContainer.attr('first-date-delivery-round'));

          let now = new Date();
          let max = new Date();
          max.setDate(now.getDate() + (maxDispo * 7));

          let currentMonthClass = 'm' + now.getMonth();
          if (now.getMonth() > dateDelivery.getMonth()) {
              currentMonthClass = 'm' + dateDelivery.getMonth();
          }

          if (calendarContainer.find('.month.' + currentMonthClass).length > 0) {
              calendarContainer.find('.prev').addClass('d-none');
          } else {
              calendarContainer.find('.prev').removeClass('d-none');
          }

          if (calendarContainer.find('.month.m' + max.getMonth()).length > 0) {
              calendarContainer.find('.next').addClass('d-none');
          } else {
              calendarContainer.find('.next').removeClass('d-none');
          }
      });

      pap.customCalendar.init(Options);
     
  },
};

pap.customFilters = {
    init: function() {
        var $tagsContainer  = $('.accordion-result'),
            $tagsReset      = $tagsContainer.find('.accordion-result__reset'),
            $tagsList       = $tagsContainer.find('.accordion-result__answers'),
            $checkbox       = $('.block-filtre .checkbox').find('input'),
            $checkboxSelect       = $('.block-filtre .checkboxSelectFiltre').find('input'),
            $textInput       = $('.block-filtre .textFiltre').find('input'),
            $treeInput       = $('.block-filtre .tree').find('a'),
            filterContainer =  $('.block-filtre'),
            $basketItems    = $('.basket-item');

        // hack pour s'assurer que le bouton validé soit correctement affiché ...
        var observer = new MutationObserver(function(mutations, observer) {
          var container = $(mutations[0].target);
          if (container.attr('x-placement') == 'top-start') {
            container.parent().find(".btn-container" ).first().css('top', '0px');
          } else {
            if(!container.parent().find(".btn-container" ).css('top') || container.parent().find(".btn-container" ).css('top') == "0px" ){
              container.parent().find(".btn-container" ).css('top', '192px'); 
            }
          }
        });

        var range = null;
        var myTimeout = null;

        var initTimeout = (range) => {

            return setTimeout(function() { 

                //remove previous amount tag
                $tagsList.find(".accordion-result__tag[data-ref*='amount:']").remove();

               var $tag = $('<div class="accordion-result__tag" data-ref="amount:' + range[0]  + '|' + range[1] +
               '"><p>' + 'montant entre ' + range[0] + ' € et ' + range[1] + ' €'  + '</p><span class="icon icon-ic_plus"></span></div>');
               $tagsList.append($tag);

               filterItems();
           
                }, 2000)
        
        }
        
          $( "#amount-slider" ).on('rangechange', (e) => {
        
            if(!myTimeout) {
                myTimeout = initTimeout(e.detail); 
            } else {
                clearTimeout(myTimeout);
                myTimeout = initTimeout(e.detail);
            }
           
        });


        $checkboxSelect.change(function(e){
        
         if(!$(this).data('ref')) return;


          if($(this).is( ":checked" ) || $(this).attr("id")  == "litepicker") {

            // On cache le filtre pour les cas de selection uniques
            if(! ( ($(this).data('ref')).includes('nutritionnelles:') || ($(this).data('ref')).includes('label:') || ($(this).data('ref')).includes('brand_code:')  )) {
          
                $(this).parent().parent().parent().hide();
            }

     
            var $tag = $('<div class="accordion-result__tag" data-ref="' + $(this).data('ref') +
                '"><p>' + $(this).val() + '</p><span class="icon icon-ic_plus"></span></div>');

            $tagsList.append($tag);
            filterItems();

            $tag.click(function() {
                var $option = $checkbox.filter('[data-ref="'+ $(this).data('ref') +'"]');

                $option.prop('checked', false);
                $(this).remove();
                filterItems();
            });
        } else {
            $tagsList.find('.accordion-result__tag[data-ref^="'+ $(this).data('ref') +'"]').remove();
            filterItems();
        }

         });

        $checkbox.change(function(e){
            if($(this).is( ":checked" )) {
                var $tag = $('<div class="accordion-result__tag" data-ref="' + $(this).data('ref') +
                    '"><p>' + $(this).val() + '</p><span class="icon icon-ic_plus"></span></div>');

                $tagsList.append($tag);
                filterItems();

                $tag.click(function() {
                    var $option = $checkbox.filter('[data-ref="'+ $(this).data('ref') +'"]');

                    $option.prop('checked', false);
                    $(this).remove();
                    filterItems();
                });
            } else {
                $tagsList.find('.accordion-result__tag[data-ref^="'+ $(this).data('ref') +'"]').remove();
                filterItems();
            }
        });

        $textInput.change(function(e){
            console.log('text filter => ref : ' + $(this).data('ref') + ' value : ' +  $(this).val() );
        });

        $(document).on('click', '.multiselect-native-select .btn-validate', function () {
          
            filterItems();
        });

        $treeInput.click(function() {
          filterItems();
        });


        $("label.checkbox").each(function(idx, item) {
            var name = $(item).attr("title"),
                $input = $(item).find(">input");

            $(item).attr("for", name);
            $(item).find($input).attr("id", name);

            $(item).find($input).insertBefore($(item));
        });

        function filterItems() {
            var filters = {};

           

            $tagsList
                .find('.accordion-result__tag')
                .each(function(idx, item) {
                    let ref = $(item).data('ref').split(':');
                    let refName = ref[0];
                    let refValue = ref[1];

                    if( typeof filters[refName] === "undefined" ) {
                        if (typeof refValue === "undefined") {
                            let obj = {};
                            obj[refName] = 1;
                            $.extend(filters, obj)
                        } else {
                            let obj = {};
                            obj[refName] = refValue;
                            $.extend(filters, obj);
                        }
                    } else {
                        if (typeof refValue === "undefined") {
                            let obj = {};
                            obj[refName] = 1;
                            $.extend(filters, obj)
                        } else {
                            let obj = {};
                            obj[refName] = filters[refName].concat('|' + refValue);
                            $.extend(filters, obj);
                        }
                    }
                });



            let filterUrl = filterContainer.attr('data-url');



            if( typeof filterUrl !== "undefined") {
                let urlParams = pap.customFilters.getUrlParams();
                urlParams['page'] = 1;
              
                let filtersKey = Object.keys(filters);
                

                // remove previous filters from url
                for(let key in urlParams) {
                    if(urlParams.hasOwnProperty(key)) {
                        if (key.substr(0, "filtres".length) === "filtres") {
                            delete urlParams[key];
                        }
                    }
                }

                filtersKey.forEach(function (key) {
                    let filterValue = 'filtres[' + key + ']';
                    urlParams[filterValue] = filters[key];
                });

                let paramsArray = [];
                for(let key in urlParams) {
                    if(urlParams.hasOwnProperty(key)) {
                        let param = key + '=' + urlParams[key];
                        paramsArray.push(param);
                    }
                }
                document.location.search = paramsArray.join('&');
            } else {
                if( filters.length === 0 ) {
                    $($basketItems).fadeIn(200);
                } else {
                    $basketItems.each(function(idx, item) {
                        var items  = $(item).data('tags').split(',');
                        var status = filters.some(function(element) {
                            return items.indexOf(element) !== -1
                        });
                        if( status ) {
                            $(item).fadeIn(200);
                        } else {
                            $(item).fadeOut(200);
                        }
                    });
                }
            }


        }
    },
    getUrlParams: function () {
        let urlParams = {};
        if (window.location.search.length > 1) {
            for (let aItKey, nKeyId = 0, aCouples = window.location.search.substr(1).split("&"); nKeyId < aCouples.length; nKeyId++) {
                aItKey = aCouples[nKeyId].split("=");
                urlParams[decodeURI(aItKey[0])] = aItKey.length > 1 ? aItKey[1] : "";
            }
        }
        return urlParams;
    }
};




pap.customScript = {
    init: function () {
        var $blockCookies = $(".block-cookies"),
            $btn_hideCookies = $blockCookies.find("#hideCookies"),
            $navToggler = $(".navbar-toggler"),
            $navLink = $("#navbarNav .navbar-nav .nav-item .nav-link"),
            $window = $(window),
            screen = $window.width();

        $('[data-toggle="tooltip"]').tooltip();


        if (screen <= 991) {
            if ($navToggler.length > 0) {
                $navToggler.on('click ', function () {
                    let icon = $(this).find(".icon");
                        $(this).find(".icon").toggleClass("icon-ic_burger icon-ic_plus");
                    $(this).find(".icon").toggleClass("rotate-45");
                    if ($(this).find(".icon-ic_plus")) {
                    //     $(this).find(".icon").toggleClass("rotate-45");
                        $(this).toggleClass("opned");
                    }
                });
            }
        }

        if (screen <= 992) {
            if ($navLink.length > 0) {
                $navLink.each(function (idx, item) {
                    if ($(item).next(".nav-link__submenu")) {
                        $(item).find(".icon").css('display', 'block');
                    } else {
                        $(item).find(".icon").css('display', 'none');
                    }

                    $(item).click(function (e) {
                        let subMenu = $(this).next(".nav-link__submenu");
                        if (subMenu.length > 0) {
                            e.preventDefault();
                            subMenu.toggleClass('open');
                            $(this).find(".icon").toggleClass("rotate-0 rotate-180");
                        }
                    });
                });
            }
        }

        if (screen <= 991) {
            if ($('.plus-moins').length > 0) {
                $('.plus-moins').each(function (idx, item) {
                    $(item).parent().siblings('div').find($('.total-ht')).append($(item));
                });
            }
        }

        if (screen <= 991) {
            var $selectOptions = $(".select-action"),
                $basketListActions = $(".block-basket-list__actions");

            if ($selectOptions.length > 0) {
                $selectOptions.click(function (e) {
                    if ($basketListActions.find(".list-action")) {
                        $basketListActions.find(".list-action").slideToggle('fast');
                        $(this).find(".icon").toggleClass("rotate-180");
                        e.preventDefault();
                    }
                });
            }
        }

        $(".clearable").each(function () {

            var $inp = $(this).find("input:text"),
                $cle = $(this).find(".clearable__clear");

            $inp.on("input", function () {
                $cle.toggle(!!this.value);
            });

            $cle.on("touchstart click", function (e) {
                e.preventDefault();
                $inp.val("").trigger("input");
            });

        });

        var btn = $('#go-top');

        $window.scroll(function () {
            if ($window.scrollTop() > 300) {
                btn.addClass('show');
            } else {
                btn.removeClass('show');
            }
        });

        btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({scrollTop: 0}, '2000');
        });

        var $btnClose = $('.block-messages .close-alert');

        $(document).on('click', '.block-messages .close-alert', function () {
            $(this).closest('.block-messages__content').remove();
        });

        btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({scrollTop: 0}, '2000');
        });

    }
};

pap.dataTable = {
  init: function () {
 
  }
};

pap.actuCarousel = {
	init: function () {
    var $actuCarousel= $('.block-actu-carousel');
    if ($actuCarousel.length > 0) {
      $actuCarousel.owlCarousel({
        items: 4,
        nav: true,
        slideSpeed : 300,
        paginationSpeed : 400,
        margin: 40,
        loop: true,
        dots: false,
        navText: ['<span class="icon icon-ic_arrow_previoust"></span>','<span class="icon icon-ic_arrow_next"></span>'],
        mouseDrag: true,
        touchDrag: false,
        responsive : {
          0 : {
            items : 1,
            mouseDrag: false,
            touchDrag: true
          },
          480 : {
            items : 2,
            mouseDrag: false,
            touchDrag: true
          },
          768 : {
            items : 3,
            mouseDrag: false,
            touchDrag: true
          },
          991 : {
            items : 4,
              mouseDrag: false,
              touchDrag: false
          }
        }
      });
      $actuCarousel.find('.owl-nav').removeClass('disabled');
      $('.owl-carousel').on('changed.owl.carousel', function(event) {
        $(this).find('.owl-nav').removeClass('disabled');
      });
    }
	}
};

pap.logoPartenaireCarousel = {
	init: function () {
    var $blockLogoCarousel= $('.block-logo-partenaire');
    $blockLogoCarousel.find('.owl-carousel').removeClass('block-home-carousel').addClass('block-logo-carousel');
    var $logoPartenaireCarousel = $('.block-logo-carousel');

    if ($logoPartenaireCarousel.length > 0) {
      $logoPartenaireCarousel.owlCarousel({
        items: 7,
        nav: true,
        loop: true,
        dots: false,
        navText: ['<span class="icon icon-ic_arrow_previoust"></span>','<span class="icon icon-ic_arrow_next"></span>'],
        mouseDrag: true,
        touchDrag: false,
        responsive : {
          0 : {
            items : 1,
            mouseDrag: false,
            nav: true,
            touchDrag: true
          },
          480 : {
            items : 2,
            mouseDrag: false,
            nav: true,
            touchDrag: true
          },
          768 : {
            items : 3,
            mouseDrag: false,
            nav: true,
            touchDrag: true
          },
          991 : {
            items : 7,
              mouseDrag: false,
              nav: true,
              touchDrag: false
          }
        }
      });
      $logoPartenaireCarousel.find('.owl-nav').removeClass('disabled');
      $('.owl-carousel').on('changed.owl.carousel', function(event) {
        $(this).find('.owl-nav').removeClass('disabled');
      });
    }
	}
};

pap.homeCarousel = {
	init: function () {
    var $homeSlider = $('.block-home-carousel');


    if ($homeSlider.length > 0) {
      $homeSlider.owlCarousel({
        items: 1,
        nav: true,
        dots: false,
        slideSpeed : 300,
        paginationSpeed : 400,
        singleItem: true,
        loop: true,
        autoplay: true,
        navText: ['<span class="icon icon-ic_arrow_previoust" alt="Précédent""></span>','<span class="icon icon-ic_arrow_next" alt="Suivant"></span>'],
        mouseDrag: true,
        touchDrag: false,
        responsive : {
          0 : {
            mouseDrag: false,
            touchDrag: true
          },
          480 : {
            mouseDrag: false,
            touchDrag: true
          },
          768 : {
            mouseDrag: false,
            touchDrag: true
          }
        }
      });
    }
	}
};

pap.sliderHeader = {
	init: function () {
    var $sliderHeader = $('.block-slider-header-carousel');

    if ($sliderHeader.length > 0) {
      $sliderHeader.owlCarousel({
        items: 1,
        nav: false, 
        dots: true, 
        loop: true,
        autoplay: true,
        autoplaySpeed: 4000,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        mouseDrag: true,
        touchDrag: true,
        responsive : {
          0 : {
            mouseDrag: false,
            touchDrag: true
          },
          480 : {
            mouseDrag: false,
            touchDrag: true
          },
          768 : {
            mouseDrag: true,
            touchDrag: true
          }
        }
      });
    }
	}
};

pap.sliderCategory = {
	init: function () {
    var $sliderCategory = $('.block-slider-category');

    if ($sliderCategory.length > 0) {
      $sliderCategory.owlCarousel({
        items: 1,
        nav: false, 
        dots: true, 
        loop: true,
        autoplay: false,
        autoplaySpeed: 4000,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        mouseDrag: true,
        touchDrag: true,
      });
    }
	}
};







pap.productCarousel = {
	init: function () {
    var $productCarousel= $('.block-product-carousel');
    if ($productCarousel.length > 0) {
      $productCarousel.owlCarousel({
        items: 5,
        nav: true,
        slideSpeed : 300,
        paginationSpeed : 400,
        dots: false,
        navText: ['<span class="icon icon-ic_arrow_previoust"></span>','<span class="icon icon-ic_arrow_next"></span>'],
        mouseDrag: true,
        touchDrag: false,
        responsive : {
          0 : {
            items : 1,
            mouseDrag: false,
            touchDrag: true
          },
          480 : {
            items : 2,
            mouseDrag: false,
            touchDrag: true
          },
          768 : {
            items : 3,
            mouseDrag: false,
            touchDrag: true
          },
          991 : {
            items : 5,
              mouseDrag: false,
              touchDrag: false
          }
        }
      });
      $productCarousel.find('.owl-nav').removeClass('disabled');
      $('.owl-carousel').on('changed.owl.carousel', function(event) {
        $(this).find('.owl-nav').removeClass('disabled');
      });
    }
	}
};

// var $owlStageOuter = $('.block-sujet-categorie').children().children().text();
// alert($owlStageOuter);
// if ($owlStageOuter === ''){
//   alert('test');
//   $('.block-sujet').addClass('d-none');

// }

pap.sujetCarousel = {
	init: function () {
    var $sujetCarousel= $('.block-sujet-categorie');
    var $owlStageOuter = $('.block-sujet-categorie').children().children().text();
    if ($owlStageOuter === ''){
      $('.block-sujet').addClass('d-none');
    }
    if ($sujetCarousel.length > 0) {
      $sujetCarousel.owlCarousel({
        // loop:true,
        margin:10,
        nav:true,
        // center:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }

      });
    }
	}
};


pap.listRubriqueAccordion = {
  init: function() {
    var $window = $(window),
    screen = $window.width();

    if (screen <= 991) {
      var $listRubrique = $(".list-rubrique"),
        $listRubriqueItem = $listRubrique.find(".list-rubrique__item");

      if ($listRubrique.length > 0) {
        $window.on("resize load ", function() {
          $listRubriqueItem.each(function(idx, item) {
            $(item).click(function(e) {
              if (
                $(item).find("ul").is(":hidden")
              ) {
                $(".list-rubrique__item ul").hide("fast");
                $(".list-rubrique__item__title .icon").addClass("rotate-0");
                $(item).find("ul").slideToggle("fast");
                $(item).find(".icon").toggleClass("rotate-0 rotate-180");
              } else {
                $(item).find("ul").slideToggle("fast");
                $(item).find(".icon").toggleClass("rotate-0 rotate-180");
              }
              e.preventDefault();
            });
          });
        });
      }
    }
  }
};

pap.nav_dropdown = {
  init: function () {
    var $dropdownNav = $('.dropdownNav'),
      $element = $dropdownNav.find('.dropdownNav__item .dropdownNav__item__label'),
      $panel = $dropdownNav.find('.dropdownNav__panel');

    if ($dropdownNav.length > 0) {
      $dropdownNav.find('.dropdownNav__label').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('dropdownNav--opened');
      });

      $element.not('.link').click(function (e) {
        e.preventDefault();
        $(this).parent().toggleClass('selected');
        $(this).parent().siblings().toggleClass('unselected');

        $(this).siblings('.dropdownNav__panel').toggleClass('selected');

        if ($(this).closest('.dropdownNav__panel').hasClass('selected')) {
          $(this).closest('.dropdownNav__panel').toggleClass('stick');
        }
      });
    }
  }
};

pap.scrollReveal = {
  init: function () {

  }
};

pap.themeCarousel = {
	init: function () {
    var $themeCarousel = $('.block-theme-carousel__slider');

    if ($themeCarousel.length > 0) {
      $themeCarousel.owlCarousel({
        items: 4,
        nav: true,
        slideSpeed : 300,
        paginationSpeed : 400,
        loop: true,
        dots: false,
        navText: ['<span class="icon icon-ic_arrow_previoust"></span>','<span class="icon icon-ic_arrow_next"></span>'],
        mouseDrag: true,
        touchDrag: false,
        responsive : {
          0 : {
            items : 1,
            mouseDrag: false,
            touchDrag: true
          },
          480 : {
            items : 2,
            mouseDrag: false,
            touchDrag: true
          },
          768 : {
            items : 3,
            mouseDrag: false,
            touchDrag: true
          },
          991 : {
            items : 4,
              mouseDrag: false,
              touchDrag: false
          }
        }
      });

      $themeCarousel.find('.owl-nav').removeClass('disabled');
    }
	}
};

pap.displayTitle = {
    init: function () {
        $(".icon-ic_help").tooltip()
    }
}


//chevrons

$('.collapse').on('shown.bs.collapse', function(){
  $(this).parent().find(".footer-icon-plus").removeClass("footer-icon-plus").addClass("footer-icon-moins");
  }).on('hidden.bs.collapse', function(){
  $(this).parent().find(".footer-icon-moins").removeClass("footer-icon-moins").addClass("footer-icon-plus");
});


//titre sous menu header
$('.nav-item').bind("mouseenter", function(){
 var divContentHeader = $(this).find(".nav-link").text();
 var titre = "<div class='titre-sous-menu-header'>"+  +"</div>";
 $('.nav-link__submenu-titre').prepend(titre);
 if ($(this).find(".titre-sous-menu-header").text()){
    // $(".overlay-css").addClass("overlay-background");
 }
 if ($(this).find(".titre-sous-menu-header-custom").text()){
//   $(".overlay-css").addClass("overlay-background");
}
});

$('.nav-item').bind("mouseleave", function(){
  $('.nav-link__submenu-background').find('.titre-sous-menu-header').remove();
 });



var hoveringOver = false;




$(".nav-item").bind("mouseleave", function(){
hoveringOver = false;
// $(".overlay-css").removeClass("overlay-background");

});



$(document).ready(function(){

    $('#closeMenu').click(function(e) {
        e.preventDefault();
        $('#menu-produits').click();
        $('#menu-nos-produits').click();

    })

    $('.submenu-open').click(function(e) {
        e.preventDefault();
    })
    // $('.menu-overflow').click(function(e) {
    //     e.preventDefault();
    //     // fermer le menu quand on clique ailleurs
    //     var $submenu = $('.nav-link__submenu');
    //     var $menuProduits = $('#menu-nos-produits , #menu-produits');
    //     var $menuOverflow = $('.menu-overflow')

    //     $submenu.removeClass('submenu-open').addClass('submenu-closed');
    //     $menuProduits.removeClass('menu-produits-open').addClass('menu-produits-closed');
    //     $(".overlay-css").removeClass("overlay-background");
    //     $menuOverflow.addClass("closed").removeClass("opened")
    // })

        $('#menu-nos-produits , #menu-produits').click(function(e) {


            // let standard link behavior for link menu and level 1 and 2 categorie link
            if($(e.target).hasClass('nav-link') || 
                e.target.parentNode.parentNode.id == "content-category-menu" ||
                e.target.parentNode.className == "sub-categories"
            ) {             
                return;
            }

            // nothing if click inside popin p
            if(e.target !== this ) {            
                // e.preventDefault(); 
                return;
            }

              
        var $submenu = $('.nav-link__submenu');
        var $menuProduits = $(this);
        // var $menuOverflow = $('.menu-overflow')
    
        if(!$submenu.hasClass('submenu-open')) {
          // Si le menu est fermé, on ouvre
          $submenu.removeClass('submenu-closed').addClass('submenu-open');
          $menuProduits.removeClass('menu-produits-closed').addClass('menu-produits-open');
          $(".overlay-css").addClass("overlay-background");
        //   $menuOverflow.addClass("opened").removeClass("closed")
        } else {

          // Si le menu est ouvert, on ferme
          $submenu.removeClass('submenu-open').addClass('submenu-closed');
          $menuProduits.removeClass('menu-produits-open').addClass('menu-produits-closed');
          $(".overlay-css").removeClass("overlay-background");
        //   $menuOverflow.addClass("closed").removeClass("opened")
        }

        e.preventDefault();



      });
  });



$("#spanNav").on('click ', function () {
$("body").addClass('overflow-hidden');
});

$("#navbarNav").on('click ', function () {
  $("body").removeClass('overflow-hidden');
  });

    $(".btn-link-custom-menu-responsive").on('click ', function (e) {


      $(this).find(".icon-custom-menu").toggleClass("rotate-0 rotate-180");
      e.preventDefault();
   
      });



      // if ( $('#navbarNav').hasClass('show')){
      //   alert('test');
     
      // };


      $(".navbar-toggler").on('click ', function (e) {
        if ( $( ".navbar-toggler" ).is( ".opned" ) ) {
 
          $(".overlay-css").removeClass("overlay-background-menu-burger");
         
        }else{
          $(".overlay-css").addClass("overlay-background-menu-burger");
        }

       
        e.preventDefault();
     
        });

    
// This method has been added, because it may not be available in all JavaScript implementations yet especially Internet Explorer.
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // A. Let Pk be ! ToString(k).
                // B. Let kValue be ? Get(O, Pk).
                // C. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // D. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // E. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        },
        configurable: true,
        writable: true
    });
}

$(document).ready(function () {
    pap.modals.trigger();

    pap.selection.init();

    // On each button for delete cart item, add a modal configured with item designation.
    pap.navAccountEvent.init();

    pap.checkCounterIsNumeric();

    $('button[id^=delete-cart-]').each(function () {
        let cartName = $(this).closest('tr').find('.cart-name').text();
        pap.modals.add('confirm', 'deleteCart', {
            id: 'modal-confirmation',
            relatedTarget: $(this).attr('id'),
            title: '',
            content: 'Vous êtes sur le point de supprimer une commande en cours. <br/> Attention cette  opération est irréversible. <br/><br/>Confirmez-vous la suppression de la commande <strong>' + cartName + '</strong> ?',
            actions: [
            //     {
            //         class: 'btn-normal no-border',
            //         text: 'Ne pas supprimer',
            //         icon: 'icon icon-ic_arrow_next',
            //         attr: [
            //             {
            //             name: 'data-dismiss',
            //             value: 'modal'
            //         },
            //         ]
            // },
                {
                    class: 'btn-normal px-5',
                    text: 'Valider',
                    attr: [
                        {
                        name: 'onclick',
                        value: 'pap.modals.doRelatedSubmit("' + $(this).attr('id') + '")'
                    }
                    ],
            }
            ]
        });
    });

        // Click event for update cart item button.
       
        $(document).on('click', '.addToWhishlist', function () {


            $('.slectOne').on('change', function() {
                $('.slectOne').not(this).prop('checked', false);
                $('#result').html($(this).data( "id" ));
                if($(this).is(":checked")){
                    $('#result').html($(this).data( "id" ));
                }else{
                    $('#result').html('Empty...!');
                }
             });

           if(!pap.wishlist.addToListInit) {
            pap.wishlist.addTolist.init();
            pap.wishlist.addToListInit = true;
           }
            
        });

        // $(document).on('load', function () {
        //     $("#search").val("");
        // });
        $( document ).ready(function() {
            $("#search").val("");
        });

        // if ($(".top-header-basket").html().length) {
        //     $(document).on('click', '.addToWhishlist', function () {
        //         pap.wishlist.addTolist.init();
        //     });
    
        // }    


    




    $(document).on('keypress', 'input[type="tel"]', function (event) {
        if ((event.which < 48 || event.which > 57) && event.which > 31 && event.which !== 43 && event.which !== 127) {
            event.preventDefault();
            return false;
        }
    });

    // Click event for show cart on My Account Page.
    $(document).on('click', 'button[id^="show-cart-"], button[id^="cart-products-"]', function () {
        // let cartId = $(this).closest('tr').find('.cart-id').text();
        let cartId = $(this).closest('.tab-liste-commande').find('.cart-id').text();
        let form = $(this).closest('form');

        form.find('input[name^="cartId"]').prop('value', cartId);

        return (cartId === form.find('input[name^="cartId"]').prop('value'));
    });

    // Action on day selection in calendar.
    $(document).on('click', '.calendar .day.active', function () {
        let calendarDom = $(this).closest('.calendar');
        calendarDom.find('.selected').removeClass('selected');
        $(this).addClass('selected');
        $(this).closest('.week').addClass('selected');

        let date = new Date($(this).closest('.week').attr('data-time'));
        let WeekDay = 0;
        for (let i = 0; i < 7; i ++) {
            if ($(this).hasClass('d' + i)) {
                WeekDay = i;
                break;
            }
        }
        date.setDate(date.getDate() + WeekDay);

        let dateString = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        calendarDom.attr('data-selected', dateString);
        $(this).closest('.modal').find('#change-delivery-date').attr('disabled', null);



        if(document.getElementById("remaining-time-modal") !== null)
        {
            let d = new Date(dateString);
            let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('fr', { month: 'long' }).format(d);
            let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);

            $('#remaining-time-modal p > span').text(`${da} ${mo}`);
        }else{



                $('#modalCalendar .modal-footer').append('<div id="remaining-time-modal" class="block-messages__content info mr-0 order-1" style="width: auto !important"><p>Vous avez choisi le <span></span> </p></div>'); 
                let d = new Date(dateString);
                let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
                let mo = new Intl.DateTimeFormat('fr', { month: 'long' }).format(d);
                let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);

                $('#remaining-time-modal p > span').text(`${da} ${mo}`);
        }

    });

    $(document).on('click', '#shop_user_logout', function () {
        pap.modals.add('confirm', 'deconnexion', {
            id: 'modal-confirmation',
            relatedTarget: $(this).attr('id'),
            title: '',
            content: 'Êtes-vous certain de vouloir vous déconnecter ?',
            actions: [
            //     {
            //         class: 'btn-normal no-border',
            //         text: 'Non',
            //         icon: 'icon icon-ic_arrow_next',
            //         attr: [{name: 'data-dismiss', value: 'modal'}]
            // },
                {
                    class: 'btn-normal btn-custom-modal-footer',
                    text: 'Valider',
                    icon: '',
                    attr: [
                        {
                        name: 'onclick',
                        value: 'location.href= "' + $(this).attr('data-option') + '";'
                    }
                    ],
            }
            ]
        });
        $('#modalConfirmation').modal('show', '#' + $(this).attr('id'));
    });

    $(document).on('shown.bs.modal', '.modal', function () {
        $(this).find('input[type=text],textarea,select').filter(':visible:first').focus();
    });
});


pap.cart = {
    timeToDisplayPriceLoader: 2,
    errors: {
        dateLimit: false,
    },
    mode: '',
    id: '',
    deliveryRounds: [],
    basket: {
        tasks: [],
        pending:{
            prices: [],
        },
        init: function () {
            pap.cart.mode = 'basket';

            pap.cart.init();

            $(document).ready(function () {
                $('#comment').on('keydown keyup', function () {
                    let limitSize = $('#comment').attr('size');
                    if ($('#comment').val().length >= limitSize) {
                        $('#comment').val($('#comment').val().substring(0, limitSize));
                    }
                    let remaining = limitSize - $('#comment').val().length;
                    $('#commentRemaining').text(remaining);
                });
                $('#reference').on('keydown keyup', function () {
                    let selector = $('#reference');
                    let limitSize = selector.attr('size');
                    if (selector.val().length >= limitSize) {
                        selector.val(selector.val().substring(0, limitSize));
                    }
                    let remaining = limitSize - selector.val().length;
                    $('#referenceRemaining').text(remaining);
                });

                pap.cart.basket.checkBasketIsEmpty();
                pap.cart.basket.checkSendAllowed();

                $('.block-add-product__content .counter__content').addClass('disabled');
            //    $('.block-add-product__content .cart-add-item').addClass('disabled');

                $(document).on('change', 'input[name=counter]', function () {
                    let itemRow = $(this).closest('.basket-item');
                    let qty = $(this).prop('value');

                    itemRow.find('button.cart-update-item').addClass('d-none');

                    if ($(this).prop('value') !== $(this).attr('data-original')) {
                    //    itemRow.find('button.cart-update-item').removeClass('d-none');
                    }

                    if (parseFloat($(this).val()) >= parseFloat($(this).attr("max"))) {
                        if (parseFloat($(this).val()) > parseFloat($(this).attr("max"))) {
                            let block = $(this).closest('.counter__content');
                            block.addClass('error');
                            block.find('.product-check-error').addClass('quantity-border');
                            block.find('.product-check-error').text('Quantité maximale en stock atteinte');
                        }
                        $(this).siblings('.plus').addClass('disabled-link');
                    }

                    if (qty === "0") {
                        itemRow.find('.cart-update-item').prop('disabled', 'disabled');
                    }
                });

                $('.block-add-product__content .product-search-reference').click(function () {
                    let reference = $('.block-add-product__content input[name=product-reference]').prop('value');
                    pap.cart.basket.checkProduct(reference);
                });

                $('.block-add-product__content input[name=product-reference]').keydown(function () {
                    $('.block-add-product__content').removeClass('error');
                    $('.block-add-product__content .counter__content').addClass('disabled');
                    $('.block-add-product__content .counter__content input[name=counter]').prop('value', 0);
                });
                $('.block-add-product__content .clearable__clear').click(function () {
                    $('.block-add-product__content').removeClass('error');
                    $('.block-add-product__content .counter__content').removeClass('border-alert');
                    $('.block-add-product__content .counter__content').addClass('disabled');
                    $('.block-add-product__content .cart-add-item').addClass('disabled');
                    $('.block-add-product__content .counter__content input[name=counter]').prop('value', 0);
                });
                $('.block-add-product__content input[name="counter"]').change(function () {
                    let block = $(this).closest('.block-add-product__content');
                    pap.cart.updateQty(block);
                    block.removeClass('error');


                    if (parseFloat($(this).val()) >= parseFloat($(this).attr("max"))) {
                        block.addClass('error');
                        block.find('.product-check-error').addClass('quantity-border');
                        block.find('.product-check-error').text('Quantité maximale en stock atteinte');
                        $(this).siblings('.plus').addClass('disabled-link');
                    }
                });

                // Event for add cart item.
                $('.block-add-product__content button.cart-add-item').click(function () {
                    let parentDom = $(this).closest('.block-add-product__content');
                    pap.cart.addCartItems([parentDom], pap.routing.basketCartItemAdd);
                    parentDom.find('.clearable__clear').trigger('click');
                });

                $(document).on('change', '.basket-item input[name="check_1"], input[name="select-all"]', function () {
                    let selectedCheckboxDom = $('.basket-item input[name="check_1"]:checked');
                    let buttonDeleteSelectedDom = $('#cart-delete-selected');
                    let selectedCount = selectedCheckboxDom.length;

                    // If no selection, disable button for delete selection.
                    buttonDeleteSelectedDom.addClass('disabled');
                    if (selectedCount > 0) {
                        buttonDeleteSelectedDom.removeClass('disabled');
                    }

                    // Set confirmation message for all selected items.
                    let message = 'Vous êtes sur le point de supprimer les articles sélectionnés de votre panier <br/>Attention cette opération est irréversible. <br/><br/>Confirmez-vous la suppression de <strong>%count%</strong> produits ?';
                    message = message.replace('%count%', selectedCount);

                    // If only one item to delete, display message from the single deletion.
                    if (selectedCount === 1) {
                        let productName = selectedCheckboxDom.closest('.basket-item').find('.cart-item-title').text();

                        message = 'Vous êtes sur le point de supprimer un article de votre panier <br/> Attention cette  opération est irréversible. <br/><br/>Confirmez-vous la suppression du produit <strong>' + productName + '</strong> ?';
                    }

                    // Configure modal to display!
                    pap.modals.add('confirm', 'deleteSelectedItem', {
                        id: 'modal-confirmation',
                        relatedTarget: 'cart-delete-selected',
                        title: 'Suppression de la sélection de produits',
                        content: message,
                        actions: [
                            {
                                class: 'btn-normal no-border',
                                text: 'Ne pas supprimer ne-pas-supprimer',
                                icon: 'icon icon-ic_arrow_next',
                                attr: [
                                {
                                    name: 'onclick',
                                    value: 'window.location.reload()'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                                
                                ]
                        },
                            {
                                class: 'btn-normal',
                                text: 'Supprimer les produits',
                                icon: 'icon icon-ic_arrow_next',
                                attr: [
                                {
                                    name: 'onclick',
                                    value: 'pap.cart.basket.deleteSelected()'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                                ],
                        }
                        ]
                    });
                });


                $(document).on('click', '.basket-item__msg .alternative-product-link', function () {
                    
                  // $(this).closest('.counter-remaining-row').find('.cart-delete-item').click();

                   var itemsSelected = $(this).closest('.basket-item').find('input[name=item-id]').val();


                   let ajax = pap.ajax;

                   ajax.url = pap.routing.cartItemRemove;
                   ajax.data = {'mode': 'basket' , 'redirect' : $(this).attr("data-redirect") , 'items': [itemsSelected], 'cartId': $('#cart-id').prop('value')};
                   ajax.dataType = 'json';
                   ajax.method = 'POST';
                   ajax.execute();
                    
                });


                $(document).on('click', '.items-orderby', function () {
                    let propertyToOrderBy = $(this).attr('data-property');

                    pap.cart.basket.orderByItems(propertyToOrderBy);
                });
                $(document).on('click', '.cart-send', function () {
                    if ($("#minimum_amount").length && $("#minimum_amount").val() != "" && parseFloat($("#total_amount").val()) < parseFloat($("#minimum_amount").val())) {
                        pap.modals.add('confirm', 'cancelNewCart', {
                            id: 'modal-confirmation',
                            relatedTarget: $(this).attr('id'),
                            title: 'Attention !!!',
                            content: "Le montant de la commande n'atteint pas le montant minimum de <strong>" + $("#minimum_amount").attr('data-text') + " €</strong> requis.",
                            actions: [
                                {
                                    class: 'btn-normal no-border',
                                    text: 'Annuler',
                                    icon: 'icon icon-ic_arrow_next',
                                    attr: [
                                        {
                                        name: 'data-dismiss',
                                        value: 'modal'
                                    },
                                    ]
                            },
                                {
                                    class: 'btn-normal',
                                    text: 'Envoyer',
                                    icon: 'icon icon-ic_arrow_next',
                                    attr: [
                                        {
                                        name: 'onclick',
                                        value: 'pap.cart.basket.sendOrder();'
                                    },
                                    {
                                        name: 'data-dismiss',
                                        value: 'modal'
                                    },
                                    ],
                            }
                            ]
                        });
                        $('#modalConfirmation').modal('show', '#' + $(this).attr('id'));
                    } else {
                        $(this).attr("disabled", true);
                        $('div[role="tooltip"]').detach();
                        pap.cart.basket.sendOrder();
                    }
                });
                pap.cart.basket.updateCartItemPrice();
            });

        },

        updateCartItemPrice: function () {
            let products = [];
            let limitPriceAvaibilityDate = new Date();
            limitPriceAvaibilityDate.setHours(limitPriceAvaibilityDate.getHours() - 1);


            $('.basket-item').each(function () {

    
                let lastUpdatePriceDate = $(this).find('input[name="product-last-update-price"]').prop('value');
                let productPriceUpdate = new Date(lastUpdatePriceDate);

                // Product price need to be updated, availability date exceed one hour.
                if (limitPriceAvaibilityDate > productPriceUpdate) {
                    let code = $(this).find('.cart-item-code').first().text();
                    let qty = $(this).find('input[name=counter]').val();

                    products.push(
                        {
                            'code': code,
                            'qty': qty
                        }
                    );

                    let deposit = $(this).find('input[name="product-deposit"]').prop('value');
                    if (deposit !== '') {
                        products.push(
                            {
                                'code': deposit,
                                'qty': qty
                            }
                        );
                    }
                }
            });

            let callback = function (data) {
                if (typeof data.content !== "undefined") {
                    pap.cart.update(data.content);
                }

                if (typeof data.tasks !== "undefined") {
                    pap.cart.basket.tasks = data.tasks;

                    let promisesUpdateBasket = [];
                    let countTasks = pap.cart.basket.tasks.length;
                    for (let nTask = 0; nTask < countTasks; nTask++) {
                        let promise = new Promise(function (resolve) {
                            setTimeout(function () {
                                resolve(pap.cart.basket.tasks[nTask]);
                            }, 500);
                        });
                        promise.then(function (task) {
                            pap.cart.checkRuningTask(task.id, 'basket', pap.cart.checkPrice)
                        });
                        promisesUpdateBasket.push(promise);
                    }
                }

            };

            pap.cart.updatePrices(products, 'basket', callback);
        },

        checkProduct: function (code) {
            let ajax = pap.ajax;
            ajax.url = pap.routing.cartcheckProduct;
            ajax.data = {
                'product-code': code,
                'cartId': pap.cart.id
            };

            ajax.dataType = 'json';
            ajax.method = 'POST';
            ajax.onSuccess = function (data) {
                let parent = $('.block-add-product__content');

                if (typeof data.message !== "undefined") {
                    parent.addClass('error');
                    parent.find('.product-check-error').html(data.message);
                    parent.find('.input[name=product-reference]').closest('.group-btn__input')
                        .addClass('not-valid');
                } else if (typeof data.product !== "undefined") {
                    $('.block-add-product__content .counter__content').removeClass('disabled');
                    $('.block-add-product__content .cart-add-item').removeClass('disabled');
                    parent.find('input[name=product-id]').prop('value', data.product.id);
                    parent.find('input[name=product-price]').prop('value', data.product.price);
                    parent.find('input[name=product-ventilation]').prop('value', data.product.ventilation);
                    parent.find('input[name=product-deposit]').prop('value', data.product.deposit);
                    parent.find('input[name=product-atomic-unit]').prop('value', data.product.atomicUnit);
                    parent.find('input[name=product-client-unit-factor]').prop('value', data.product.clientUnitFactor);
                    parent.find('input[name=product-is-ucc-handle]').prop('value', data.product.isUccHandle);

                    parent.find('input[name=counter]').attr('data-step', 1);
                    parent.find('input[name=counter]').prop('value', 1);
                    parent.find('input[name=counter]').attr('max', data.product.stock);
                    parent.find('input[name=counter]').attr('min', data.product.atomicUnit);

                    $('.block-add-product__content input[name="counter"]').trigger('change');
                }
            };
            ajax.onError = function (data) {
                if (typeof data.responseJSON !== "undefined") {
                    let response = data.responseJSON;
                    if (typeof response.message !== "undefined") {
                        let parent = $('.block-add-product__content');
                        parent.addClass('error');
                        parent.find('.product-check-error').html(response.message);
                        parent.find('.input[name=product-reference]').closest('.group-btn__input')
                            .addClass('not-valid');
                    }
                }
            };
            ajax.execute();
        },

        checkBasketIsEmpty: function () {
            $('.basket-item-empty').removeClass('d-none');
            $('.block-basket-list__somme').addClass('d-none');
            $('.block-adresse').addClass('d-none');

            if ($('.basket-item').length > 0) {
                $('.basket-item-empty').addClass('d-none');
                $('.block-basket-list__somme').removeClass('d-none');
                $('.block-adresse').removeClass('d-none');
            }
        },

        deleteSelected: function () {
            let itemsSelected = [];
            $('.basket-item input[name="check_1"]:checked').each(function () {
                let itemRow = $(this).closest('.basket-item');
                itemsSelected.push(itemRow.attr('data-item'));
            });
            pap.cart.itemRemove(itemsSelected);
            $('#cart-delete-selected').addClass('disabled');
        },

        orderByItems: function (property) {
            let ajax = pap.ajax;
            let cartId = $('#cart-id').prop('value');

            ajax.url = pap.routing.cartItemOrderBy;
            ajax.data = {'cartId': cartId, 'property': property};
            ajax.dataType = 'json';
            ajax.method = 'POST';
            ajax.onSuccess = function (data) {
                pap.cart.update(data['content']);
            };
            ajax.execute();
        },
        checkDeliveryRounds: function (date, callback) {
            var roundsAvailable = [];
              // On recupère les types de livraisons disponibles
              $('#cart_deliveryRoundType option').each((key, value )=> {
                roundsAvailable.push(value.value);
            }
            );
            let ajax = pap.ajax;

            ajax.url = pap.routing.deliveryRounds;
            ajax.data = {'date': date};
            ajax.dataType = 'json';
            ajax.method = 'POST';
            ajax.onSuccess = function (result) {
               var rounds = [];


                result.data.data.forEach(deliveryRound => {
                    if ( roundsAvailable.includes(deliveryRound.type)) {
                        rounds.push(deliveryRound);
                    }
                    

                });
                result.data.data = rounds;

                callback(result);
            };
            ajax.execute();

        },

        checkSendAllowed: function () {
            let sendOrderDOM = $('.cart-send');
            sendOrderDOM.addClass('disabled');

            // Check if products are waiting to update quantity (Button is visible).
            let areItemsReady = ($('.cart-update-item:not(.d-none)').length === 0);

            // Check all product in assortment.
            let areItemsAvailable = ($('.basket-item.diseabled').length === 0 && $('.basket-item').length > 0);

            // Check if all product are with all quantity available in stock.
            let areItemsInStock = ($('.stock-agency-available:not(.d-none)').length === 0);

            // Check Date limit to validate order.
            let now = new Date();
            let DateLimitIsOk = false;

            if ($('input[name="cart-delivery-limit"]').prop('value') != '') {
                let limit = pap.datetimeToTimestamp($('input[name="cart-delivery-limit"]').prop('value'));
                DateLimitIsOk = (now.getTime() < limit);
            }

            if (areItemsReady && areItemsAvailable && areItemsInStock && DateLimitIsOk) {
                sendOrderDOM.removeClass('disabled');
            }
        },

        sendOrder: function () {
            let ajax = pap.ajax;
            let cartId = $('#cart-id').prop('value');
            let reference = $('#reference').prop('value');
            let comments = $('#comment').prop('value');

            ajax.url = pap.routing.cartSendOrder;
            ajax.data = {'cartId': cartId, 'reference': reference, 'comments': comments};
            ajax.dataType = 'json';
            ajax.method = 'POST';
            ajax.onSuccess = function (data) {
                if (typeof data['message'] !== "undefined") {
                    let message = data['message'];
                    let modalError = $('#modalError');
                    message = message.replace(/\n/g, '<br>');

                    modalError.find('.modal-title').html('Validation de la commande impossible');
                    modalError.find('.modal-body').html(message);
                    modalError.find('.modal-footer button').attr('onclick', 'window.location.reload(true);');
                    modalError.modal('show');
                }    
                else {

                    if (typeof data['trackInfos'] !== "undefined" ) {

                        // Matomo
                        if(typeof _paq !== 'undefined') {
                            data.trackInfos.items.forEach(item => {
                                // Product Array
                                _paq.push(['addEcommerceItem',
                                item.item_id, // (required) SKU: Product unique identifier
                                item.item_name, // (optional) Product name
                                item.item_category, // (optional) Product category. You can also specify an array of up to 5 categories eg. ["Books", "New releases", "Biography"]
                                item.price, // (Recommended) Product Price
                                item.quantity
                                ]);                    
                            });

                            _paq.push(['trackEcommerceOrder',
                                data.trackInfos.transaction_id, // (Required) orderId
                                data.trackInfos.value // (Required) grandTotal (revenue)
                                // (Optional) subTotal
                                // (optional) tax
                                // (optional) shipping
                                // (optional) discount
                            ]);
                        }

                        // Ga
                        if (window['google_tag_manager']) {
                            gtag('event', 'purchase', {
                                'transaction_id': data.trackInfos.transaction_id,
                                'value': data.trackInfos.value,
                                'affiliation': data.trackInfos.affiliation,
                                'tax': data.trackInfos.tax,
                                'shipping': data.trackInfos.shipping,
                                'currency': data.trackInfos.currency,
                                'coupon': data.trackInfos.coupon,                         
                                'items':  data.trackInfos.items,
                                'event_callback': function() {
                                    window.location.href = pap.routing.cartConfirmation;
                                  }
                              });                          
                        } else {
                            window.location.href = pap.routing.cartConfirmation;
                        }
                    }
                }
            };
            ajax.onError = function (data) {
                if (typeof data.responseJSON !== "undefined" && data.responseJSON.message !== "undefined") {
                    let message = data.responseJSON.message;
                    let modalError = $('#modalError');
                    modalError.find('.modal-title').html('Validation de la commande impossible');
                    modalError.find('.modal-body').html(message.replace(/\n/g, '<br>'));
                    modalError.find('.modal-footer button').attr('onclick', 'window.location.reload(true);');
                    modalError.modal('show');
                }

                if (typeof data['content'] !== "undefined") {
                    pap.cart.update(data['content']);
                }
            };
            ajax.execute();
        }
    },
    lists: {
        customers: [],
        agencies : [],
        customersInit: function () {
            pap.cart.mode = 'list';
            $(document).ready(function () {
                let formDom = $(this).closest('form');
                    // Get list of customers.
                let customers = pap.cart.lists.customers;
                let modalCustomers = $('#modalCustomers');
                let blockCustomersDOM = modalCustomers.find('.block-customer');
                let customerHtmlPrototype = modalCustomers.find('.block-agency__item.prototype');
                let customerDom = {};

                // Set the agency list content empty.
                blockCustomersDOM.html('');

                // For each agency, add the agency block in list.

                for (var c in customers) {
                    let customer = customers[c];
                    customerDom = $(customerHtmlPrototype).clone();
                    customerDom.removeClass('hidden').removeClass('prototype');
                    customerDom.find('.block-agency__item__content h3').text('N° ' + customer.customerCode + ' - ' + customer.customerName);

                    customerDom.find('.agency-select').attr('data-id', customer.customerId);
                    customerDom.find('.agency-select').on(
                        'click',
                        function () {
                            $('#customerId').prop('value', customer.customerId);
                            $('#home-assortement-').attr('id', 'home-assortement-' + customer.customerId);
                            $('#home-assortement-' + customer.customerId).trigger('click');
                        }
                    );
                    blockCustomersDOM.append(customerDom);
                }

                // Open the pop-in to select agency.
                modalCustomers.modal('show');
                // Intercept the submit.
                return false;
            });
        },
        init: function () {
            pap.cart.mode = 'list';
            $(document).ready(function () {
                $(document).on('click', 'form .card-body .action-controls button[type=submit][name=action]', function (event) {
                    let formDom = $(this).closest('form');
                    let mode = $(this).prop('value');
                    let buttonID = $(this).attr('id');

                    // Get customer ID from form.
                    let customerId = formDom.find('input[name="customerId"]').prop('value');
                    // Get list of agencies for selected customer.
                    let agencies = pap.cart.lists.agencies[customerId];

                    // If only one agency, select the first.
                    if (agencies.length === 1) {
                        let agency = agencies[0];
                        formDom.find('input[name="agencyId"]').prop('value', agency.agencyId);

                        if (agency.agencyTypes.length === 0 && $(this).prop('value') === 'cart_new') {
                            pap.modals.displayError(null, 'Création de commande impossible. Aucune tournée disponible pour ce couple client/agence')
                            event.preventDefault();
                            formDom.find('input[name="agencyId"]').prop('value', 0);
                        }
                    }

                    // Get the selected agency ID.
                    let agencyId = formDom.find('input[name="agencyId"]').prop('value');

                    // IF selected agency is empty, open the pop-in to select agency.
                    if (agencyId === '') {
                        let modalAgencies = $('#modalAgencies');
                        let blockAgenciesDOM = modalAgencies.find('.block-agency');
                        modalAgencies.find('input[name="customer-id"]').prop('value', customerId);
                        let agencyHtmlPrototype = modalAgencies.find('.block-agency__item.prototype');
                        let agencyDom = {};

                        // Set the agency list content empty.
                        blockAgenciesDOM.html('');

                        // For each agency, add the agency block in list.
                        for (let i = 0; i < agencies.length; i++) {
                            if (mode === "cart_new" && parseInt(agencies[i].orderAllowed) === 0) {
                                continue;
                            }

                            agencyDom = $(agencyHtmlPrototype).clone();
                            agencyDom.removeClass('hidden').removeClass('prototype');
                            agencyDom.find('.block-agency__item__content h3').text(agencies[i].agencyName);
                            agencyDom.find('.block-agency__item__content p').text(agencies[i].agencyTypes.join(' / '));

                            if (agencies[i].agencyTypes.length === 0) {
                                agencyDom.find('.block-agency__item__content p').text('Aucune tournée disponible');
                                agencyDom.find('.block-agency__item__content p').addClass('red');

                                agencyDom.find('.overlay').removeClass('hidden');
                            }

                            agencyDom.find('.agency-select').attr('data-id', agencies[i].agencyId);
                            agencyDom.find('.agency-select').on(
                                'click',
                                function () {
                                    pap.cart.lists.selectAgency(
                                        '#' + buttonID + '',
                                        customerId,
                                        $(this).attr('data-id')
                                    );
                                }
                            );
                            blockAgenciesDOM.append(agencyDom);
                        }

                        // Open the pop-in to select agency.
                        modalAgencies.modal('show');
                        // Intercept the submit.
                        return false;
                    }
                    // If Selected Agency id is given, do the submit.
                    if (agencyId > 0) {
                        return true;
                    }
                    return false;
                });
            });
        },
        selectAgency: function (action, customer, agencyId) {
            let customerForm = $('#account-accordion')
                .find('input[name="customerId"][value="' + customer + '"]')
                .closest('form')
            ;
            if (customerForm.length == 0) {
                customerForm = $('#account-accordion')
                    .find('input[name="customerId"]')
                    .closest('form')
                ;
            }
            customerForm.find('input[name="agencyId"]').prop('value', agencyId);
            customerForm.find(action).trigger('click');
        }
    },
    catalog: {
        tasks: [],
        printing: false,

        selectMobileTaxon: function () {
            let mobileTaxonDom = $('#mobilityTaxons');
            let currentTaxon = mobileTaxonDom.attr('data-taxon-current');

            if (currentTaxon === "") {
                return;
            }
            let taxonDom = $('#taxon-' + currentTaxon);
            let itemIndex = 0;
            let panelIndex = 0;
            mobileTaxonDom.find('.dropdownNav__label').text(taxonDom.text());
            taxonDom.parent().find('> .dropdownNav__panel').addClass('selected');

            taxonDom.parentsUntil('#mobilityTaxons').each(function () {
                if ($(this).hasClass('dropdownNav__item') || $(this).hasClass('dropdownNav__panel')) {
                    if ($(this).hasClass('dropdownNav__item')) {
                        itemIndex++;
                    } else if ($(this).hasClass('dropdownNav__panel')) {
                        panelIndex++;
                    }

                    if ($(this).hasClass('dropdownNav__item')) {
                        if (itemIndex === 1 && taxonDom.hasClass('link')) {
                            $(this).addClass('current');
                        } else {
                            $(this).addClass('selected');
                            $(this).siblings().addClass('unselected');
                        }
                    }

                    if ($(this).hasClass('dropdownNav__panel')) {
                        $(this).addClass('selected');
                        if ((panelIndex > 1 && taxonDom.hasClass('link') === true) || taxonDom.hasClass('link') === false) {
                            $(this).addClass('stick');
                        }
                    }
                }
            });
        },

        init: function () {
            pap.cart.mode = 'assortment';
            pap.cart.init();

            $(document).ready(function () {
                pap.cart.catalog.selectMobileTaxon();

                $('.dropdownNav__label').on('click', function () {
                    if ($(this).closest('.dropdownNav').hasClass('dropdownNav--opened')) {
                        $('#mobilityTaxons').css({height: '-webkit-fill-available'});
                        pap.cart.catalog.selectMobileTaxon();
                    } else {
                        $('#mobilityTaxons').css({height: 'auto'});
                    }
                });



                $('button[id^=cart-delete-item]').each(function () {
                    let productName = $(this).closest('.basket-item').find('.cart-item-title').text();
                    pap.modals.add('confirm', 'deleteCartItem', {
                        id: 'modal-confirmation',
                        relatedTarget: $(this).attr('id'),
                        title: '',
                        content: 'Vous êtes sur le point de supprimer un article de votre panier <br/>Attention cette  opération est irréversible. <br/><br/>Confirmez-vous la suppression du produit <strong>' + productName + '</strong> ?',
                        actions: [
                            {
                                class: 'btn-normal no-border ne-pas-supprimer',
                                text: 'Ne pas supprimer',
                                icon: 'icon icon-ic_arrow_next',
                                attr: [
                                    {
                                    name: 'onclick',
                                    value: 'window.location.reload()'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                                ]
                        },
                            {
                                class: 'btn-normal',
                                text: 'Supprimer le produi dddt',
                                icon: 'icon icon-ic_arrow_next',
                                attr: [
                                    {
                                    name: 'onclick',
                                    value: 'pap.modals.doRelatedSubmit("' + $(this).attr('id') + '")'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                                ],
                        }
                        ]
                    });
                });

                // Event for add cart item.
                $('.basket-item').on('click','button.add_items', function () {
                    let item = $(this).closest('.basket-item');
                    if (!item.hasClass('wishlist-item')) {
                        $(this).addClass('disabled');
                    }
                    //pap.cart.addCartItem(item, pap.routing.assortmentCartItemAdd);
                    pap.cart.addCartItems([item], pap.routing.assortmentCartItemAdd);
                });

                // Event for add cart item.
                $(document).on('click', 'body.catalog a.print-pdf', function (event) {
                    event.preventDefault();
                    pap.cart.catalog.print();
                });

                // Event for add cart item.
                $(document).on('click', 'body.sales-record a.print-pdf', function (event) {
                    event.preventDefault();
                    pap.cart.catalog.print();
                });

                let currentDate = $('input[name="cart-delivery-date"]').prop('value');


                if (pap.cart.catalog.tasks.length && currentDate !== '') {
                    pap.cart.loadingTaskPrices(pap.cart.catalog.tasks, pap.cart.catalog.checkTasksFinished);
                }
            });
        },

        refreshCatalog: function () {
            let slug = $('.selectedCategory').data('slug');
            let urlParams = pap.customFilters.getUrlParams();
            urlParams = Object.defineProperty(urlParams, 'mode', {
                value: pap.cart.mode,
                enumerable: true
            });
            urlParams = Object.defineProperty(urlParams, 'slug', {
                value: slug,
                enumerable: true
            });
            if (typeof urlParams.search !== "undefined" && urlParams.search !== '') {
                urlParams = Object.defineProperty(urlParams, 'sort_name', {
                    value: 'score',
                    enumerable: true
                });
                urlParams = Object.defineProperty(urlParams, 'sort_order', {
                    value: 'desc',
                    enumerable: true
                });
            }

            let ajax = pap.ajax;
            ajax.url = pap.routing.catalogRefresh;
            ajax.data = urlParams;
            ajax.dataType = 'json';
            ajax.method = 'GET';
            ajax.onSuccess = function (data) {
                pap.cart.checkPrice(data);
            };
            ajax.execute();
        },

        updateItemPrice: function () {
            let products = [];
            $('.basket-item').each(function () {
                products.push(
                    {
                        'code': $(this).find('.cart-item-code').first().text(),
                        'qty': 1
                    }
                );

                let deposit = $(this).find('input[name="deposit-product"]').attr('value');
                if (typeof deposit !== "undefined" && deposit !== '') {
                    products.push(
                        {
                            'code': deposit,
                            'qty': 1
                        }
                    );
                }
            });

            let callback = function (data) {
                if (typeof data.tasks !== "undefined") {
                    pap.cart.catalog.tasks = data.tasks;
                }

                let promisesUpdate = [];
                for (let nTask = 0; nTask < pap.cart.catalog.tasks.length; nTask++) {
                    let promise = new Promise(function (resolve) {

                        setTimeout(function () {
                            resolve(pap.cart.catalog.tasks[nTask]);
                        }, 500);
                    });
                    promise.then(function (task) {
                        pap.cart.checkRuningTask(task.id, 'assortment', pap.cart.checkPrice)
                    });
                    promisesUpdate.push(promise);
                }
            };
            pap.cart.updatePrices(products, 'assortment', callback);
        },

        checkTasksFinished: function (data) {
            if (typeof data !== 'undefined' && typeof data.id !== 'undefined' && typeof data.status !== 'undefined') {
                let task = pap.cart.catalog.tasks.find(function (element) {
                    return element.id === data.id;
                });
                task.status = data.status;

                if (task.status !== 'finished') {
                    if (task.status == 'failed') {
                        pap.modals.progressClose();
                        pap.modals.displayError(null, 'Une erreur est survenue pendant la mise à jour des prix.');
                        pap.cart.catalog.tasks = [];
                        return false;
                    }
                    setTimeout(function () {
                        pap.cart.checkRuningTask(task.id, 'assortment', pap.cart.catalog.checkTasksFinished);
                    }, 500);

                    return false;
                }

                let finished = 0;
                let max = pap.cart.catalog.tasks.length;
                for (let i = 0; i < max; i++) {
                    if (pap.cart.catalog.tasks[i].status === 'finished') {
                        finished++;
                    }
                }
                pap.modals.progressModal('#modalPricesOperating', finished, max);

                if (finished >= max) {
                    if (pap.cart.catalog.printing) {
                        pap.cart.catalog.print();
                    }
                    else {
                        pap.cart.catalog.refreshCatalog();
                    }
                }
                return true;
            }
        },

        print: function () {
            pap.cart.catalog.printing = true;
            let ajax = pap.ajax;
            ajax.url = pap.routing.printAssortment;
            ajax.dataType = undefined;
            ajax.method = 'GET';
            ajax.onSuccess = function (data, textStatus, jqXHR) {
                let contentType = jqXHR.getResponseHeader('content-type');

                if (contentType === 'application/json') {
                    let jsonData = data;
                    if (jsonData.tasks !== 'undefined') {
                        pap.cart.catalog.tasks = [];
                        let max = jsonData.tasks.length;
                        for (let i = 0; i < max; i++) {
                            pap.cart.catalog.tasks.push({'id': jsonData.tasks[i]['id'].toString(), 'status': jsonData.tasks[i]['status'] });
                        }

                        if (pap.cart.catalog.tasks.length) {
                            pap.cart.loadingTaskPrices(pap.cart.catalog.tasks, pap.cart.catalog.checkTasksFinished);
                        }
                    }
                } else {
                    pap.modals.progressClose();
                    window.open(pap.routing.printAssortment, '_blank');
                    pap.cart.catalog.printing = false;
                }
            };
            ajax.execute();
        }
    },
    product: {
        tasks: [],
        init: function () {
            pap.cart.mode = 'assortment';

            pap.cart.init();
            $(function(){
                // Event for add cart item.
                $('.basket-item').on('click','button.add_items', function () {
                    let item = $(this).closest('.basket-item');

                    var voile = item.find('.basket-item__counter').find('.voile');

                    // Afficher le voile en modifiant le style
                    voile.css("display", "block");

                    pap.cart.addCartItems([item], pap.routing.assortmentCartItemAdd);
                });

                // Display loading prices only if customer and agency are selected.
                if (pap.customers.current > 0 && pap.customers.currentAgency > 0) {
                    pap.cart.loadingTaskPrices(pap.cart.product.tasks, pap.cart.product.checkTasksFinished);

                    // If no task runnong for updating prices, trigger the change quantity to display disgressive prices
                    if (pap.cart.product.tasks.length === 0) {
                        $('input[name="counter"]').trigger('change');
                    }
                }
            });
            $(document).scroll(function (event) {
                let screenWidth = $(window).width();

                if (screenWidth > 991) {
                    let productPrice = $('.block-product-details__price');
                    // productPrice.removeClass('block-fixed');
                    let origOffsetY = productPrice.offset().top;
                    let originLeft = productPrice.offset().left;

                    if ($(window).scrollTop() + 10 + parseInt(productPrice.css('margin-top')) >= origOffsetY) {
                        // productPrice.addClass('block-fixed');
                        productPrice.css('left', originLeft);
                    }
                }
            });
        },
        checkTasksFinished: function (data) {
            if (typeof data !== 'undefined' && typeof data.id !== 'undefined' && typeof data.status !== 'undefined') {
                let task = pap.cart.product.tasks.find(function (element) {
                    return element.id === data.id;
                });
                task.status = data.status;

                if (task.status !== 'finished') {
                    if (task.status == 'failed') {
                        pap.modals.progressClose();
                        pap.modals.displayError(null, 'Une erreur est survenue pendant la mise à jour des prix.');
                        pap.cart.product.tasks = [];
                        return false;
                    }
                    setTimeout(function () {
                        pap.cart.checkRuningTask(task.id, 'assortment', pap.cart.product.checkTasksFinished);
                    }, 500);

                    return false;
                }

                let finished = 0;
                let max = pap.cart.product.tasks.length;
                for (let i = 0; i < max; i++) {
                    if (pap.cart.product.tasks[i].status === 'finished') {
                        finished++;
                    }
                }
                pap.modals.progressModal('#modalPricesOperating', finished, max);

                if (finished >= max) {
                    // if (pap.routing.catalogRefresh == '') {
                        document.location.reload(true);
                    // } else {
                    //     pap.cart.catalog.refreshCatalog();
                    // }
                }
                return true;
            }
        }
    },
    new: {
        init: function () {
            $(document).ready(function () {
                // Init date.
               
                
                let dateAvailable = [];
                let roundsAvailable = [];
                let deliveryRounds = pap.cart.deliveryRounds;

                // On recupère les types de livraisons disponibles
                $('#cart_deliveryRoundType option').each((key, value )=> {
                    roundsAvailable.push(value.value);
                }
                );

                    for (let i = 0; i < deliveryRounds.length; i++) {

                        let today = new Date();
                        let limit = new Date();

                        if ( !roundsAvailable.includes(deliveryRounds[i].type)) {
                            continue;
                        }
                        limit = new Date(deliveryRounds[i].date);
                        let timesParsed = deliveryRounds[i].time_limit.split(':');

                        limit.setHours( parseInt(timesParsed[0]) , parseInt(timesParsed[1]) ,parseInt(timesParsed[2]) );
                        // Test if deliveryRound date is allowed and add date on calendar to active.

                            // if (today.getTime() < limit.getTime()) {

                        limit.setDate(limit.getDate() - parseInt(deliveryRounds[i].day_limit));
                        limit.setHours(timesParsed[0], timesParsed[1], timesParsed[2]);

                            if (today.getTime() < limit.getTime() ) {
                           

                                if (dateAvailable[deliveryRounds[i].date]) {
                                    dateAvailable[deliveryRounds[i].date] = 'multiple';
                                } else {
                                    dateAvailable[deliveryRounds[i].date] = deliveryRounds[i].type;
                                }              
                            }
                        
                    }

                    pap.customCalendar.render(dateAvailable);
                    pap.customCalendar.renderLegend(dateAvailable);
                    

            

                $('#cart_existing_valid').prop('disabled', 'disabled');
                $(document).on('change', 'input[name="affectToOrder"]', function () {
                    $('.order-panel').addClass('d-none');
                    let panelIdToShow = $(this).attr('data-target');
                    $(panelIdToShow).removeClass('d-none');
                });

                let affectToOrderDOM = $('input[name="affectToOrder"]:checked');
                if (affectToOrderDOM.length > 0) {
                    affectToOrderDOM.trigger('change');
                }
            });

            $(document).on('click', 'button[name="cart[cancel]"]', function (event) {
                event.preventDefault();
                $(this).attr('data-action', '?cart[cancel]');
                pap.modals.add('confirm', 'cancelNewCart', {
                    id: 'modal-confirmation',
                    relatedTarget: $(this).attr('id'),
                    title: '',
                    content: 'Vous êtes sur le point d’annuler la création de cette commande.\n' + 'Attention cette opération est irréversible. Confirmez-vous ce choix ?',
                    actions: [{
                        class: 'btn-normal no-border',
                        text: 'Non',
                        icon: 'icon icon-ic_arrow_next',
                        attr: [{
                            name: 'data-dismiss',
                            value: 'modal'
                        }]
                    },
                    {
                        class: 'btn-normal',
                        text: 'Oui',
                        icon: 'icon icon-ic_arrow_next',
                        attr: [{
                            name: 'onclick',
                            value: 'pap.modals.doRelatedSubmit("' + $(this).attr('id') + '")'
                        },
                        {
                            name: 'data-dismiss',
                            value: 'modal'
                        }],
                    }]
                });
                $('#modalConfirmation').modal('show', '#' + $(this).attr('id'));
            });
            $(document).on('change', '#existing-cart', function () {
                $('#cart_existing_valid').attr('disabled', 'disabled');
                if ($(this).prop('value') != 0) {
                    $('#cart_existing_valid').attr('disabled', null);
                }
            });

            // Action on day selection in calendar.
            $(document).on('click', '.calendar .day.active', function () {
                $(this).closest('.calendar').find('.selected').removeClass('selected');
                $(this).addClass('selected');
                $(this).closest('.week').addClass('selected');

                let date = new Date($(this).closest('.week').attr('data-time'));
                let WeekDay = 0;
                for (let i = 0; i < 7; i ++) {
                    if ($(this).hasClass('d' + i)) {
                        WeekDay = i;
                        break;
                    }
                }

               

                date.setDate(date.getDate() + WeekDay);

                let dateString = date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
              
  
                pap.cart.basket.checkDeliveryRounds(dateString,function(response) {


                    var checkSubmit = (deliveryRounds) => {

                        $('#cart_deliveryRoundDate').attr('value', dateString);
                        let type = $('#cart_deliveryRoundType option:checked').val();


                        
                    for (let i = 0; i < deliveryRounds.length; i++) {
                        // if (deliveryRounds[i].type == type && new Date(deliveryRounds[i].date) > new Date()) {
                            let d = new Date(dateString);
                            let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
                            let mo = new Intl.DateTimeFormat('fr', { month: 'long' }).format(d);
                            let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);

                        if (deliveryRounds[i].type === type && deliveryRounds[i].date === dateString ) {
                            $('#cart_deliveryRound').val(deliveryRounds[i].id);
                            let limit = new Date(deliveryRounds[i].date);
                            limit.setDate(limit.getDate() - parseInt(deliveryRounds[i].day_limit));
                            let timesParsed = deliveryRounds[i].time_limit.split(':');
                            limit.setHours(timesParsed[0], timesParsed[1], timesParsed[2]);
    
                            let timeRemaining = timeRemainingToString(new Date(), limit);
                            
                            // let d = new Date(dateString);
                            // let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
                            // let mo = new Intl.DateTimeFormat('fr', { month: 'long' }).format(d);
                            // let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);
                           

    
                            $('#no-date-selected').hide();
                            $('#remaining-time').show();
                            $('#remaining-time p > span').text(`${da} ${mo}`);
                            $('.icon-ic_arrow_next-custom-new-command').removeClass('icon-grey').addClass('icon-green');
                            $('#cart_valid').prop('disabled', false);
    
                            // $('#modalDeliveryRound .modal-title').text(`${da} ${mo}`);
                      
    
                        }
                    }
                };

 
                    if (response.data.data.length < 2) {

                        $('#cart_deliveryRoundDate').attr('value', response.date);
                        document.getElementById("cart_deliveryRoundType").value = response.data.data[0].type;
                        //checkSubmit(deliveryRounds);
                        checkSubmit(pap.cart.deliveryRounds);
                        
                    } else {

                        let d = new Date(dateString);
                        let ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(d);
                        let mo = new Intl.DateTimeFormat('fr', { month: 'long' }).format(d);
                        let da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(d);

                        $('#modalDeliveryRound .modal-title').text(`${da} ${mo}`);

                    let modalDeliveryRounds = $('#modalDeliveryRound');

                    var ul = document.getElementById("delivery-rounds-select");
                    ul.innerHTML = "";
                    response.data.data.forEach( (round) => {

                        var li = document.createElement("li");
                        li.setAttribute("style", 'cursor: pointer; list-style: none; margin-top: 20px');                
                        var span = document.createElement("span");                  
                        span.setAttribute("class", round.type); 
                        li.appendChild(span);
                        li.appendChild(document.createTextNode(round.description));
                        ul.appendChild(li);
    
                        li.onclick = function(e) {
    
                            $('#cart_deliveryRoundDate').attr('value', response.date);
                            document.getElementById("cart_deliveryRoundType").value = round.type;
                            
                            modalDeliveryRounds.modal('hide');
                            // checkSubmit(deliveryRounds);
                            checkSubmit(pap.cart.deliveryRounds);
            
                        };
                     });
                    modalDeliveryRounds.modal({show: true, backdrop : 'static'});
                }
                });
            });
        },
    },

    addCartItem: function (productParent, url) {
        let cartId = $('#cart-id').prop('value');

        let clientQuantity = productParent.find('input[name=product-client-quantity]').val();
        let salesQuantity = productParent.find('input[name=product-sales-quantity]').val();
        let productCode = productParent.find('input[name=product-reference]').prop('value');
        let productPrice = productParent.find('input[name=product-price]').prop('value');
        let productVentilation = productParent.find('input[name=product-ventilation]').prop('value');
        let deposit = productParent.find('input[name=product-deposit]').prop('value');



        pap.cart.itemAdd(
            url,
            cartId,
            productCode,
            clientQuantity,
            salesQuantity,
            productPrice,
            productVentilation,
            deposit
        );
    },
    getProductItem: function (productParent) {
        let productCode = productParent.find('input[name=product-reference]').prop('value');
        let qty = productParent.find('input[name=counter]').prop('value');

        let productItem = {
            cartItemId: productParent.attr('data-item'),
            productId: productParent.attr('data-product'),
            productName: productParent.find('.cart-item-title').text(),
            productCode: productCode,
            clientQuantity: productParent.find('input[name=product-client-quantity]').val(),
            salesQuantity: productParent.find('input[name=product-sales-quantity]').val(),
            minQuantity: productParent.find('input[name=counter]').attr('min'),
            maxQuantity: productParent.find('input[name=counter]').attr('max'),
            productPrice: productParent.find('input[name=product-price]').prop('value'),
            productVentilation: productParent.find('input[name=product-ventilation]').prop('value'),
            deposit: productParent.find('input[name=product-deposit]').prop('value'),
            depositPrice: productParent.find('input[name=product-deposit-price]').prop('value'),
            isUccHandle: productParent.find('input[name=product-is-ucc-handle]').prop('value'),
            clientUnitFactor: productParent.find('input[name=product-client-unit-factor]').prop('value'),
            lastUpdatePrice: productParent.find('input[name=product-last-update-price]').prop('value'),
            atomicUnit: productParent.find('input[name=product-atomic-unit]').prop('value')
        };

        return {productCode: productCode, qty: qty, productItem: productItem};
    },
    addCartRemainingItem: function(itemId, qty, url) {
        pap.cart.itemsAdd(
            url,
            cartId,
            productItems
        );

    },
    addCartItems: function (productParents, url) {
        
        if (productParents.length > 0) {
            let parent = productParents[0];
            // Check if we're on wishlist detail page in order to change cart mode.
            if (parent.hasClass('wishlist-item')) {
                pap.cart.mode = 'wishlist';
            }
        }
        let cartId = $('#cart-id').prop('value');
        let productItems = [];

        // Create product items.
        productParents.forEach(function (productParent) {
            let productData = pap.cart.getProductItem(productParent);
            let productCode = productData.productCode;
            let qty = productData.qty;
            let productItem = productData.productItem;
            qty = parseFloat(qty);
            if (!qty) {
                if(productItem.isUccHandle == 0) {
                productItem.salesQuantity = productItem.atomicUnit;
                productItem.clientQuantity = 0;
                } else {
                productItem.clientQuantity = productItem.atomicUnit / productItem.clientUnitFactor;
                productItem.salesQuantity =    productItem.atomicUnit;
                
            }

            } 
            productItems.push(productItem);
        });


        {
           // Treat all products.
            pap.cart.itemsAdd(
                url,
                cartId,
                productItems
            );
        }
    },
    updateTotalPrice: function (qty, unitPrice) {
        return qty * unitPrice;
    },
    uccQuantityToSalesQuantity: function (qty, salesUnitFactor) {
        return qty * salesUnitFactor;
    },
    checkRuningTask: function (taskId, mode, successCallBack) {
        let ajax = pap.ajax;
        ajax.url = pap.routing.getTaskFinishedResult;
        ajax.data = {
            'mode': mode,
            'task': taskId
        };

        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {
            var callback = successCallBack;
            callback(data);
        };
        ajax.execute();
    },
    updatePrices: function (products, mode,successCallBack ) {
        if (products.length === 0) {
            return false;
        }
        if (typeof mode === "undefined") {
            mode = pap.cart.mode;
        }
        let cartId = $('#cart-id').prop('value');
        $.ajax({
            type: 'POST',
            url: pap.routing.cartUpdatePrice,
            data:  {
                'products': products,
                'cartId': cartId,
                'mode' : mode,
            },
            dataType: 'json',
            success: function (data) {
               successCallBack(data)
            }
        });
    },

    checkPrice: function (data) {
        pap.modals.displayError(data);
        if (typeof data.content !== "undefined") {
            pap.cart.update(data.content);
        }
        $('#modalLoading').modal('hide');
        pap.modals.progressClose();
    },
    loadingTaskPrices: function (tasks, checkTasksFinished) {

        /*
        if (tasks.length > 0) {
            setTimeout(function () {
                let progressCurrent = $('#modalPricesOperating .progress-bar').prop('aria-valuenow');
                let progressMax = $('#modalPricesOperating .progress-bar').prop('aria-valuemax');
                if (typeof progressCurrent == "undefined" || (progressCurrent > 0 && progressMax > 0)) {
                    $('#modalPricesOperating').modal({
                        show: true,
                        backdrop: 'static'
                    });
                }
            }, parseInt(pap.cart.timeToDisplayPriceLoader,10) * 1000);
        }

        */
        let tasksLength = tasks.length;
        if (tasksLength > 0) {
            for (let nTask = 0; nTask < tasksLength; nTask++) {
                pap.cart.checkRuningTask(tasks[nTask].id, pap.cart.mode, checkTasksFinished);
            }
        }
    },
    update: function (data) {
        // wait a little bit for the response from the web service
        setTimeout(function () {
            $('#modalLoading').modal('hide');
        }, 1000);
        pap.modals.progressClose();

    

        if (data['total']) {
            $('.block-basket-list__somme').replaceWith(data['total']);
        }

        if (data['product']) {
            data['item'] = data['product'];
        }


        if (data['item']) {
            let items = Object.keys(data['item']);

            for (let i = 0; i < items.length; i++) {
            // $('#datatable-cmd').html('');
            let itemRow = $('.basket-item[data-product="' + items[i] + '"]');
                
                if (itemRow.length > 0) {
                    //itemRow.replaceWith(data['item'][items[i]]);

                    const parser = new DOMParser();
                    const document = parser.parseFromString(data['item'][items[i]], "text/html");
                
                    const counterDom = itemRow.find('.basket-item__counter');

                    const counter = document.querySelectorAll(".basket-item")[0].querySelectorAll(".basket-item__counter")[0];

                    counterDom.replaceWith(counter);
                    counterDom.show();

                    //

                }
            }
        }
        if (data['info']) {
            $('.block-info-basket').replaceWith(data['info']);
        }
        if (data['list-items']) {
            $('.block-basket-list').replaceWith(data['list-items']);
        }
        if (data['page-content']) {
            $('.page-content').replaceWith(data['page-content']);
            window.scrollTo(0, 0);
        }
        if (data['top-basket']) {
            $('.top-header-basket').replaceWith(data['top-basket']);
        }

        if (pap.cart.mode == 'basket') {
            pap.cart.basket.checkBasketIsEmpty();
            pap.cart.basket.checkSendAllowed();
        }

        pap.basketChecklist.init();
    },

    init: function () {
        $(document).ready(function () {
            let cartId = $('#cart-id').prop('value');
            if (typeof cartId !== "undefined") {
                pap.cart.id = cartId;
            }

            $('input[name="product-price"]').each(function () {
                if (parseFloat($(this).val()) === 0) {
                    let item = $(this).closest('.basket-item');
                    item.find('.counter__content input[name="counter"]').data('order', 'false');
                }
            });

            $(document).on('click', '.cart-delete-item', function () {
                let productName = $(this).closest('.basket-item').find('.cart-item-title').text();

                let message = 'Vous êtes sur le point de supprimer un article de votre panier <br/>Attention cette  opération est irréversible. <br/><br/>Confirmez-vous la suppression du produit <strong>' + productName + '</strong> ?';

                pap.modals.add('confirm', 'deleteCartItem', {
                    id: 'modal-confirmation',
                    relatedTarget: $(this).attr('id'),
                    title: '',
                    content: message,
                    actions: [
                        {
                            class: 'btn-normal no-border ne-pas-supprimer',
                            text: 'Ne pas supprimer',
                            icon: 'icon icon-ic_arrow_next',
                            attr: [
                                {
                                    name: 'onclick',
                                    value: 'window.location.reload()'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                            ]
                        },
                        {
                            class: 'btn-normal',
                            text: 'Supprimer le produit',
                            icon: 'icon icon-ic_arrow_next',
                            attr: [
                                {
                                    name: 'onclick',
                                    value: 'pap.modals.doRelatedSubmit("' + $(this).attr('id') + '")'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                            ],
                        }
                    ]
                });
                $('#modalConfirmation').modal('show', '#' + $(this).attr('id'));
            });

            $(document).on('click', '#cart-change-delivery-date', function () {
                $("#modalorderbanner").modal('hide');
                let alerts = [];
                let currentDate = $('input[name="cart-delivery-date"]').prop('value');

                let title = 'Choisir votre date de livraison';
                let description = '';
                if (pap.cart.errors.dateLimit === true) {
                    title = 'La date désirée est dépassée';
                    description = '';
                    alerts.push({
                        'type': 'alert-danger',
                        'content': 'Merci de choisir une nouvelle date de livraison dans le calendrier ci-dessous',
                    })
                }

                let calendarActions = [
                    {
                        class: 'btn-normal icon-change-delivery-date order-2',
                        text: 'Valider',
                        icon: '',
                        attr: [
                            {
                                name: 'id',
                                value: 'change-delivery-date'
                            },
                            {
                                name: 'disabled',
                                value: 'disabled'
                            },
                            {
                                name: 'onclick',
                                value: 'pap.cart.changeDeliveryDate($(\'#modalCalendar .calendar\').attr(\'data-selected\'));'
                            },
                            {
                                name: 'data-dismiss',
                                value: 'modal'
                            },
                        ],
                    }
                ];
                if (pap.cart.errors.dateLimit === false) {
                    calendarActions.unshift({
                        class: 'btn-normal d-none',
                        label: 'Ne pas changer la date',
                        icon: '',
                        attr: [
                            {
                                name: 'data-dismiss',
                                value: 'modal'
                            },
                        ]
                    });
                } else {
                    calendarActions.unshift({
                        class: 'btn-normal',
                        label: 'Retourner à l\'espace client',
                        icon: 'icon icon-ic_arrow_next',
                        attr: [
                            {
                                name: 'onclick',
                                value: 'window.location.replace(pap.routing.customerAccount);'
                            },
                        ]
                    });
                }

                pap.modals.add('calendar', 'selectDeliveryRoundDate', {
                    id: 'modalCalendar',
                    relatedTarget: $(this).attr('id'),
                    title: title,
                    alerts: alerts,
                    description: description,
                    content: '<section class="block block-calendar"><div data-date="" class="calendar"></div></section>',
                    actions: calendarActions,
                });

                $('#modalCalendar').modal(
                    {
                        'show': true,
                        'backdrop': 'static'
                    },
                    '#' + $(this).attr('id')
                );
            });

            $(document).on('show.bs.modal', '#modalCalendar.modal', function () {
                let today = new Date();
                let limit = new Date();

                let type = $('input[name="cart-delivery-type"]').val();
                let dateAvailable = [];
                let deliveryRounds = pap.cart.deliveryRounds;



                for (let i = 0; i < deliveryRounds.length; i++) {
                    limit = new Date(deliveryRounds[i].date);
                    limit.setDate(limit.getDate() - parseInt(deliveryRounds[i].day_limit));
                    let timesParsed = deliveryRounds[i].time_limit.split(':');
                    limit.setHours(timesParsed[0], timesParsed[1], timesParsed[2]);
                    // Test if deliveryRound date is allowed and add date on calendar to active.
                    if (deliveryRounds[i].type === type) {
                        if (today.getTime() < limit.getTime()) {
                            if (dateAvailable[deliveryRounds[i].date]) {
                                dateAvailable[deliveryRounds[i].date] = 'multiple';
                            } else {
                                dateAvailable[deliveryRounds[i].date] = deliveryRounds[i].type;
                            }  
                        }
                    }
                }

                let currentDate = '';
                let currentDateString = '';

                if ( $('input[name="cart-delivery-date"]').prop('value') === '' || pap.cart.errors.dateLimit === true) {
                    currentDateString = new Date().toISOString().split('T')[0];
                } else {
                    currentDate = $('input[name="cart-delivery-date"]').prop('value');
                    currentDateString = currentDate.slice(0, 4) + '/' + currentDate.slice(4, 6) + '/' + currentDate.slice(6, 8);
                }

                $(this).find('.calendar').attr('data-date', currentDateString);
                if( deliveryRounds[0] != undefined && deliveryRounds[0].date != undefined) {
                    $(this).find('.calendar').attr('first-date-delivery-round', deliveryRounds[0].date);
                }
                pap.customCalendar.render(dateAvailable);
                pap.customCalendar.renderLegend(dateAvailable);
            });

            
            $(document).on('change', '.basket-item .counter__content input[name=counter]', function () {
                let itemRow = $(this).closest('.basket-item');

                let counterRow = itemRow.find('.basket-item__counter');

                let isInCart = (counterRow.attr('data-item-id') > 0);

             

                if (isInCart) {
                    itemRow.find('button.cart-update-item').addClass('d-none');
                    itemRow.find('button.cart-delete-item').removeClass('d-none');
                    if (parseFloat($(this).prop('value')) === 0) {
                        $(this).val($(this).attr('data-original'));
                        itemRow.find('.cart-delete-item').trigger('click');
                        return;
                    }
                 
                } else {
                    if ($(this).prop('value') > 0 && $(this).data('order') === true) {
                        itemRow.find('button.add_items').removeClass('disabled');
                    }
                }

                
                if ($(this).prop('value') !== $(this).attr('data-original') && $(this).prop('value') > 0) {
                    if (pap.cart.basket.pending.prices[counterRow.attr('data-item-id')] && pap.cart.basket.pending.prices[counterRow.attr('data-item-id')].length > 0 ) {
                            pap.cart.basket.pending.prices[counterRow.attr('data-item-id')].forEach(p => clearTimeout(p));
                            pap.cart.basket.pending.prices[counterRow.attr('data-item-id')] = [];
                            
                        } else {
                            pap.cart.basket.pending.prices[counterRow.attr('data-item-id')] = [];
                        } 
                    pap.cart.basket.pending.prices[counterRow.attr('data-item-id')].push(setTimeout(function () {
                                pap.cart.digressivePrice(itemRow);
                                }, 1000)
                            );
                    
                }
    
            });

            pap.cart.checkDeliveryLimit();
        });
    },

    checkDeliveryLimit: function () {
        let currentDate = $('input[name="cart-delivery-date"]').prop('value');

        if ((pap.cart.catalog.tasks.length > 0 || pap.cart.basket.tasks.length > 0) && currentDate !== '') {
          //  return '';
        }

        let limitToOrder = $('input[name="cart-delivery-limit"]').prop('value');
        if (limitToOrder) {
            let now = new Date().getTime();
            limitToOrder = new Date(limitToOrder).getTime();

            if( now > limitToOrder){
                pap.cart.errors.dateLimit = true;
                $('#cart-change-delivery-date').trigger('click');
            }
        }
        else {
            pap.cart.errors.dateLimit = true;
            $('#cart-change-delivery-date').trigger('click');
        }
    },

    changeDeliveryDate: function (dateSelected) {
        let ajax = pap.ajax;
        let cartId = $('#cart-id').prop('value');

        ajax.url = pap.routing.cartUpdateDeliveryRound;
        ajax.data = {
            'cartId': cartId,
            'mode': pap.cart.mode,
            'date': dateSelected
        };
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {
            document.location.reload(true);
        };
        ajax.execute();
    },

    digressivePrice: function(itemRow) {
        let products = [];
        let code = itemRow.find('.cart-item-code').first().text();
        let deposit = itemRow.find('input[name="product-deposit"]').prop('value');
        let qty = parseFloat(itemRow.find('input[name=counter]').prop('value'));
        let isUCCHandle = itemRow.find('input[name="product-is-ucc-handle"]').prop('value');
        let clientUnitFactor = parseFloat(itemRow.find('input[name="product-client-unit-factor"]').prop('value'));

        
        products.push(
            {
                'code': code,
                'qty': qty,
                'deposit': deposit
            }
        );

        var voile = itemRow.find('.basket-item__counter').find('.voile');

        // Afficher le voile en modifiant le style
        voile.css("display", "block");

        
        let callback = function(data) {
  
            if (typeof data['price'] !== "undefined") {
                let prices = data['price'];
                if (deposit != '' && typeof prices[deposit] !== "undefined") {
                    let depositUnitPrice = Math.round(prices[deposit]['price'] * 100) / 100;
                    let depositPrice = Math.round((depositUnitPrice * qty) * 100) / 100;
                    itemRow.find('input[name="product-deposit-price"]').prop('value', depositUnitPrice);
                    itemRow.find('.deposit-price').text(depositPrice);
                }

                let pricePanel = itemRow.find('.basket-item__counter');
                let itemId =pricePanel.data('item-id');

                let salesQty = qty;
                let clientQty = 0;

                if (isUCCHandle == "1")  {
                    salesQty = qty * clientUnitFactor ;
                    clientQty = qty;
                } 
                pap.cart.itemsUpdate(itemId, clientQty, salesQty);
                
            }
        };
        
        pap.cart.updatePrices(products, 'digressive', callback);

    },
    updateQty: function (itemRow) {
        // DOM Selector
        let salesQuantityDOM = itemRow.find('input[name="product-sales-quantity"]');
        let clientQuantityDOM = itemRow.find('input[name="product-client-quantity"]');
        let stockInfoMessage = itemRow.find('.stock-agency-available');
        let depositPrice = parseFloat(itemRow.find('input[name="product-deposit-price"]').prop('value'));

        // Get is UCC Handle
        let isUCCHandle = itemRow.find('input[name="product-is-ucc-handle"]').prop('value');
       
        // Get current quantity input
        let qtyDOM = itemRow.find('input[name=counter]');
        let qty = parseFloat(qtyDOM.prop('value'));
        let max = parseFloat(qtyDOM.prop('max'));

        // Get current price in float format (0.0)
        let unitPrice = parseFloat(itemRow.find('input[name=product-price]').prop('value'));
        // Get Atomic Unit in float format (0.0)
        let atomicUnit = 1;
        if (itemRow.find('.cart-item-atomic-unit ').length > 0) {
            atomicUnit = parseFloat(itemRow.find('.cart-item-atomic-unit ')
                .text().replace(',', '.'));
        } else if (itemRow.find('input[name="product-atomic-unit"]').length > 0) {
            atomicUnit = parseFloat(itemRow.find('input[name="product-atomic-unit"]').val());
        }


        // Test if quantity available with atomic unit
        qty = parseFloat(atomicUnit * Math.round(qty / atomicUnit));
        qty = qty.toFixed(2);

        if (qty > max) {
            let factor = parseInt(max / atomicUnit);
            qty = atomicUnit * factor;
            if (qty < 0) {
                qty = 0;
            }
        }

        // Disable add_items button if qty === 0
        if (qty === 0 && !itemRow.hasClass('wishlist-item') && qtyDOM.data('order') === true) {
          //  itemRow.find('button.add_items').addClass('disabled');
           itemRow.find('button.cart-update-item').addClass('disabled');
        }


        // Get Unit Factor in float format (0.0)
        let clientUnitFactor = 1;
        if (isUCCHandle === "1") {
            clientUnitFactor = parseFloat(itemRow.find('input[name="product-client-unit-factor"]').prop('value'));
        }

        let salesQty = qty;
        let totalPrice = pap.cart.updateTotalPrice(salesQty, unitPrice);
        itemRow.find('.total-ht-amount').text(totalPrice.toFixed(3).replace('.', ','));
        

        itemRow.find('input[name=counter]').prop('value', qty);
        stockInfoMessage.addClass('d-none');
        if (!itemRow.hasClass('diseabled')) {
            itemRow.removeClass('border-alert');
        }
        if (itemRow.find('.counter__content').hasClass('border-alert')) {
            stockInfoMessage.removeClass('d-none');
            itemRow.find('.basket-item__msg > p').text('Quantité demandé supérieure à la quantité disponible');
            itemRow.addClass('border-alert');
        }
        salesQuantityDOM.attr('value', qty);
        clientQuantityDOM.attr('value', 0);

        if (isUCCHandle == 1) {
            salesQuantityDOM.prop('value', salesQty);
            clientQuantityDOM.prop('value', Math.round(qty / clientUnitFactor));
            itemRow.find('.cart-item-sales-qty-to-ucc').text(salesQty.toFixed(2).replace('.', ','));
        }
    },

    itemRemove: function (items) {
        let cartId = $('#cart-id').prop('value');
        let ajax = pap.ajax;

        ajax.url = pap.routing.cartItemRemove;
        ajax.data = {'mode': pap.cart.mode, 'items': items, 'cartId': cartId};
        ajax.dataType = 'json';
        ajax.method = 'POST';

        if (window['google_tag_manager']) {

            let itemRowDOM = $('.basket-item[data-item=' + items + ']');

            let productCode =  itemRowDOM.find('input[name="product-reference"]').prop('value');
            let productName = itemRowDOM.find('input[name="product-name"]').prop('value');

            gtag("event", "remove_from_cart", {
                currency: "EUR",
                items: [{item_id: productCode,item_name: productName }],
                'event_callback': function() {
                    ajax.execute();
                  }
              });                                     
        } else {
            ajax.execute();
        } 
 
    },

    itemUpdate: function (item, qty) {
        let itemRowDOM = $('.basket-item[data-item=' + item + ']');
        // let qty = $('.basket-item[data-item=' + item + '] .basket-item__counter input').prop('value');

        let salesQty = itemRowDOM.find('input[name="product-sales-quantity"]').prop('value');
        let clientQty = itemRowDOM.find('input[name="product-client-quantity"]').prop('value');




        
        let ajax = pap.ajax;
        ajax.url = pap.routing.cartItemUpdate;
        ajax.data = {
            'mode': pap.cart.mode,
            'itemId': item,
            'clientQty': clientQty,
            'salesQty': salesQty
        };
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {

            pap.cart.update(data['content']);
        };
        ajax.execute();
    },

    itemsUpdate: function (itemId, clientQty, salesQty, temperature) {
        if (temperature === undefined) {
            temperature = '';
        }
        let itemsToUpdate = [];

        itemsToUpdate.push({itemId: itemId, clientQty: clientQty, salesQty: salesQty});
        
        let ajax = pap.ajax;
        let mode = pap.cart.mode;
        ajax.data = {
            'mode': mode,
            'items': itemsToUpdate
        };
        ajax.url = pap.routing.cartItemUpdate;
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {
           

            if (data['content'] !== undefined) {
                pap.cart.update(data['content']);
            }
          
        };
        ajax.execute();
    },
    itemRemainingAdd: function(url,itemId,qty) {
        let ajax = pap.ajax;
        ajax.url = url;
        ajax.data = {
            'itemId': itemId,
            'qty': qty,
            'mode': pap.cart.mode
        };
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.execute();

    },

    itemAdd: function (url, cartId, productCode, clientQuantity, salesQuantity, priceUnit, priceVentilation, deposit) {
        priceUnit = (typeof priceUnit !== 'undefined') ? priceUnit : 0;
        priceVentilation = (typeof priceVentilation !== 'undefined') ? priceVentilation : '';
        deposit = (typeof deposit !== 'undefined') ? deposit : '';
        let ajax = pap.ajax;

        ajax.url = url;
        ajax.data = {
            'cart': cartId,
            'mode': pap.cart.mode,
            'product-code': productCode,
            'client-unit-quantity': clientQuantity,
            'sales-unit-quantity': salesQuantity,
            'price-unit': priceUnit,
            'price-ventilation': priceVentilation,
            'deposit-reference': deposit,
        };

        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {
            /**
             * Update content
             */
            if (typeof data['content'] !== "undefined") {
                pap.cart.update(data['content']);
            }
            if (typeof data['code'] !== "undefined" && data['code'] === "KO" && typeof data['message'] !== "undefined") {
                pap.modals.displayError(data);
            }
        };

        ajax.execute();
    },

    itemsAdd: function (url, cartId, productItems) {
        let products = [];
        let ajax = pap.ajax;

        productItems.forEach(function (productItem) {
            let productCode = productItem.productCode;
            let clientQuantity = productItem.clientQuantity;
            let salesQuantity = productItem.salesQuantity;
            let min = productItem.minQuantity;
            let max = productItem.maxQuantity;
            let priceUnit = productItem.productPrice;
            let priceVentilation = productItem.productVentilation;
            let deposit = productItem.deposit;
            let isUccHandle = productItem.isUccHandle;
            let depositPrice = productItem.depositPrice;
            let clientUnitFactor = productItem.clientUnitFactor;
            let lastUpdatePrice = productItem.lastUpdatePrice;

            priceUnit = (typeof priceUnit !== 'undefined') ? priceUnit : 0;
            priceVentilation = (typeof priceVentilation !== 'undefined') ? priceVentilation : '';
            deposit = (typeof deposit !== 'undefined') ? deposit : '';
            let product = {
                'product-code': productCode,
                'client-unit-quantity': clientQuantity,
                'sales-unit-quantity': salesQuantity,
                'price-unit': priceUnit,
                'price-ventilation': priceVentilation,
                'deposit-reference': deposit,
                'is-ucc-handle': isUccHandle,
                'deposit-price': depositPrice,
                'client-unit-factor': clientUnitFactor,
                'last-update-price': lastUpdatePrice,
                'min-quantity' : min,
                'max-quantity': max,
            };
            products.push(product);
        });

        ajax.url = url;
        let mode = pap.cart.mode;
        ajax.data = {
            'cart': cartId,
            'mode': mode,
            'products' : products
        };
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {

            /**
             * Update content
             */
            if (typeof data['content'] !== "undefined") {
                pap.cart.update(data['content']);
            }
            if (typeof data['code'] !== "undefined" && data['code'] === "KO" && typeof data['message'] !== "undefined") {
                pap.modals.displayError(data);
            }
        };

          if (window['google_tag_manager']) {

            let items = [];
         
            
            for(let key in products) {
                
                items.push(
                    {
                        item_id: products[key]['product-code'],
                        quantity: parseFloat(products[key]['sales-unit-quantity'].replace(",", ".")),
                        price: parseFloat(products[key]['price-unit'].replace(",", "."))
                    })
                    
            }

            gtag("event", "add_to_cart", {
                currency: "EUR",
                items: items,
                'event_callback': function() {
                    ajax.execute();
                  }
              });

         } else {
            ajax.execute();
         }

        
    }
};
pap.customers = {
    availables: [],
    availablesAgencies:[],
    current: 0,
    currentAgency: 0,
    displayCustomerSelection: function(callback) {

        let modalCustomers = $('#modalCustomers');
        let blockCustomersDOM = modalCustomers.find('.block-customer');
        let customerHtmlPrototype = modalCustomers.find('.block-agency__item.prototype');
        let customerDom = {};

        if (typeof callback === "undefined") {
            modalCustomers.modal({show: false});
            setTimeout(function () {
                pap.modals.displayError([], 'Une interne est survenue, impossible de trouver la fonction de retour');
            }, 500);
        }
        // Disable the dismiss button
        modalCustomers.find('a[data-dismiss="modal"]').addClass('invisible');

        // Set the agency list content empty.
        blockCustomersDOM.html('');

        // For each agency, add the agency block in list.
        for (var c in pap.customers.availables) {
            let customer = pap.customers.availables[c];
            customerDom = $(customerHtmlPrototype).clone();
            customerDom.removeClass('hidden').removeClass('prototype');
            customerDom.find('.block-agency__item__content h3').text('N° ' + customer.customerCode + ' - ' + customer.customerName);
            customerDom.find('.agency-select').attr('data-id', customer.customerId);
            customerDom.find('.agency-select').on(
                'click',
                function () {
                    modalCustomers.modal({show: false});
                    setTimeout(function () {
                        pap.customers.current = customer.customerId;
                        pap.customers.availablesAgencies = customer.agencies;

                        if (pap.customers.currentAgency === 0 && pap.customers.availablesAgencies.length > 0) {
                            if (pap.customers.availablesAgencies.length === 1) {
                                pap.customers.currentAgency = pap.customers.availablesAgencies[0].agencyId;
                            } else {
                                pap.customers.displayAgencySelection(callback);
                                return false;
                            }
                        }

                        callback();
                    }, 500);

                    // $('#customerId').prop('value', customer.customerId);
                    // $('#home-assortement-').attr('id', 'home-assortement-' + customer.customerId);
                    // $('#home-assortement-' + customer.customerId).trigger('click');
                }
            );
            blockCustomersDOM.append(customerDom);
        }

        if (pap.customers.availables.length === 1) {
            pap.customers.current = pap.customers.availables[0].customerId;
            pap.customers.availablesAgencies = pap.customers.availables[0].agencies;
            pap.customers.displayAgencySelection(callback);
            return false;
        }

        // Open the pop-in to select agency.
        modalCustomers.modal({show: true, backdrop : 'static'});
        // Intercept the submit.
        return false;
    },
    displayAgencySelection: function(callback) {
         let modalAgencies = $('#modalAgencies');
        let blockAgenciesDOM = modalAgencies.find('.block-agency');
        modalAgencies.find('input[name="customer-id"]').prop('value', pap.customers.current);
        let agencyHtmlPrototype = modalAgencies.find('.block-agency__item.prototype');
        let agencyDom = {};

        if (typeof callback === "undefined") {
            modalAgencies.modal({show: false});
            setTimeout(function () {
                pap.modals.displayError([], 'Une interne est survenue, impossible de trouver la fonction de retour');
            }, 500);
        }

        // Disable the dismiss button
        modalAgencies.find('a[data-dismiss="modal"]').addClass('invisible');

        // Set the agency list content empty.
        blockAgenciesDOM.html('');

        // For each agency, add the agency block in list.
        for (let i = 0; i < pap.customers.availablesAgencies.length; i++) {
            let j = i;
            if (pap.cart.mode === "cart_new" && parseInt(pap.customers.availablesAgencies[j].orderAllowed) === 0) {
                continue;
            }

            agencyDom = $(agencyHtmlPrototype).clone();
            agencyDom.removeClass('hidden').removeClass('prototype');
            agencyDom.find('.block-agency__item__content h3').text(pap.customers.availablesAgencies[j].agencyName);
            agencyDom.find('.block-agency__item__content p').text(pap.customers.availablesAgencies[j].agencyTypes.join(' / '));

            if (pap.customers.availablesAgencies[j].agencyTypes.length === 0) {
                agencyDom.find('.block-agency__item__content p').text('Aucune tournée disponible');
                agencyDom.find('.block-agency__item__content p').addClass('red');

                agencyDom.find('.overlay').removeClass('hidden');
            }

            agencyDom.find('.agency-select').attr('data-id', pap.customers.availablesAgencies[j].agencyId);
            agencyDom.find('.agency-select').on(
                'click',
                function () {
                    pap.customers.currentAgency = pap.customers.availablesAgencies[j].agencyId;

                    callback();
                }
            );
            blockAgenciesDOM.append(agencyDom);
        }

        // Open the pop-in to select agency.
        modalAgencies.modal('show');
        // Intercept the submit.
        return false;
    },
    saveCustomerAgencySession: function(data) {
        if (typeof data === "undefined") {
            $('.modal.show').modal('hide');
            setTimeout(function () {
                pap.modals.displayError([], 'Sélection impossible. Certaines données sont manquantes.')
            }, 500);
        }
        let ajax = pap.ajax;
        ajax.url = pap.routing.customerSelect;
        ajax.data = data;
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.onSuccess = function (data) {

        };

        ajax.execute();
    }
};
pap.routing = {
    customerAccount: '',
    cartUpdatePrice: '',
    getTaskFinishedResult: '',
    basketCartItemAdd: '',
    basketCartRemainingItemAdd: '',
    assortmentCartItemAdd: '',
    cartItemOrderBy: '',
    cartItemUpdate: '',
    cartSendOrder: '',
    cartConfirmation: '',
    cartOrderisSent: '',
    cartItemRemove: '',
    cartSelection: '',
    cartSecurityLoginRender: '',
    cartSecurityLoginForgetRender: '',
    catalogRefresh: '',
    cartSecurityLogin: '',
    customerSelect: '',
    updateWishlistItemQty: '',
    deleteWishlistItem: '',
    createWishlist: '',
    wishlistsIndex: '',
    wishlistCartItemsContent: '',
    wishlistDelete: '',
    wishlistAddAllItemsToBasket: '',
    wishlistsSelectorRefreshList: '',
    addToWishlist: '',
    dematWorkflowClose: '',
    dematWorkflowRender: '',
};
pap.ajax = {
    url: '',
    data: {},
    method: 'GET',
    dataType: 'html',
    result: {},
    onSuccess: function () {
        return true;
    },
    onError: function (data) {
        let message = 'Une erreur interne est survenue';
        if (typeof data.responseJSON !== "undefined") {
            let response = data.responseJSON;
            if (response.message) {
                message = response.message;
            }
        }

        let modalError = $('#modalError');
        modalError.find('.modal-body').html(message);
        modalError.modal('show');
    },

    execute: function () {
        $.ajax({
            type: this.method,
            url: this.url,
            data: this.data,
            dataType: this.dataType,
            success: function (data, textStatus, jqXHR) {
                /**
                 * Redirect to cart selection if not cart exist
                 */
                if (typeof data.redirectToCartSelection !== "undefined" && data.redirectToCartSelection) {
                    window.location.href = pap.routing.cartSelection;
                    return;
                }

                /**
                 * Reload current page
                 */
                if (typeof data.reload !== "undefined" && data.reload) {
                    document.location.reload(true);
                    return;
                }

                /**
                 * Reload current page
                 */
                if (typeof data.redirect !== "undefined" && data.redirect) {
                    document.location.href = data.redirect;
                    return;
                }

                pap.ajax.onSuccess(data, textStatus, jqXHR);
            },
            error: function (jqXHR) {
                if (typeof jqXHR.status !== "undefined" && jqXHR.status === 403) {
                    pap.modals.displaySessionExpired();
                }

                if (typeof jqXHR['content'] !== "undefined") {
                    pap.cart.update(jqXHR['content']);
                }
                pap.ajax.onError(jqXHR);
            }
        });
    }
};

pap.modals = {
    lists: [],
    add: function (type, name, options) {
        let modalIndex = pap.modals.findRelatedModal(options.relatedTarget);

        if (modalIndex < 0) {
            this.lists.push(
                {
                    'name': name,
                    'type': type,
                    'options': options
                }
            )
        } else {
            this.lists[modalIndex] = {
                'name': name,
                'type': type,
                'options': options
            };
        }
    },

    // find the modal to the correspondant relatedTarget
    findRelatedModal: function (relatedTarget) {
        var index = -1;
        var list = pap.modals.lists;
        for (var i = 0; i < list.length; ++i) {
            if (list[i].options.relatedTarget === relatedTarget) {
                index = i;
                break;
            }
        }
        return index;
    },

    // find the modal to the correspondant target
    findTargetModal: function (relatedTarget) {
        return pap.modals.lists.find(function (currentValue) {
            if (currentValue.options.relatedTarget === $(relatedTarget).attr('id')) {
                return true;
            }
        });
    },

    // Enable events
    trigger: function () {
        $(document).on('show.bs.modal', '.modal', function (event, body, forcedTarget) {
            let target = event.relatedTarget;
            if (typeof target === "undefined" && typeof forcedTarget !== "undefined") {
                target = forcedTarget;
            }

            let modalIndex = pap.modals.findRelatedModal($(target).attr('id'));
            if (modalIndex === -1 && target !== undefined) {
                // modalIndex = pap.modals.findRelatedModal(target.replace('#', ''));
                
            }

            let modalObj = pap.modals.lists[modalIndex];
            if (typeof modalObj === "undefined" && typeof body !== "undefined") {
                modalObj = {options: {content: body}};
            }

            let modal = $(this);

            if (typeof modalObj !== "undefined") {
                if (modalObj.options.title) {
                    modal.find('.modal-title').text(modalObj.options.title);
                }
                modal.find('.modal-desc').hide();
                if (modalObj.options.description) {
                    modal.find('.modal-desc').html(modalObj.options.description);
                    modal.find('.modal-desc').show();
                }

                if (modalObj.options.alerts) {
                    modal.find('.block-alerts').html('');
                    for (let alts = 0; alts < modalObj.options.alerts.length; alts++) {
                        var alert = $('<p class="alert"></p>');
                        alert.text(modalObj.options.alerts[alts].content);
                        alert.addClass(modalObj.options.alerts[alts].type);
                        modal.find('.block-alerts').append(alert);
                    }
                    modal.find('.block-alerts').show();
                }
                if (modalObj.options.content) {
                    modal.find('.modal-body').html(modalObj.options.content);
                }
                if (modalObj.options.actions) {
                    modal.find('.modal-footer').html('');

                    if (modalObj.options.actions) {
                        for (let i = 0; i < modalObj.options.actions.length; i++) {
                            let button = $('<button type="button" class="btn m-1"></button>');
                            let attributes = modalObj.options.actions[i].attr;

                            if (modalObj.options.actions[i].label) {
                                button.text(modalObj.options.actions[i].label);
                            }
                            if (modalObj.options.actions[i].text) {
                                button.append('<span class="btn-normal__text">' + modalObj.options.actions[i].text + '</span>');
                            }
                            button.attr('class', button.attr('class').concat(' ' + modalObj.options.actions[i].class));
                            for (let att = 0; att < attributes.length; att++) {
                                button.attr(attributes[att].name, attributes[att].value)
                            }

                            if (modalObj.options.actions[i].icon) {
                                button.append('<i class="' + modalObj.options.actions[i].icon + '"></i>');
                            }

                            modal.find('.modal-footer').append(button);
                        }
                    }
                }
            }
        })
    },

    // do the submit of Related Target button
    doRelatedSubmit: function (relatedTarget) {
        let relatedTargetDom = $('#' + relatedTarget);
        let callback = relatedTargetDom.attr('data-callback');

        if (callback) {
            eval(callback);
        } else {
            let formDom = relatedTargetDom.closest('form');
            formDom
                .attr('action', relatedTargetDom.attr('data-action'))
                .attr('method', relatedTargetDom.attr('data-method'))
                .submit();
        }

    },

    // Display a modal to the message error
    displayError: function (data, message) {
        $('.modal.show').removeClass('show');
        var message = (typeof message !== 'undefined') ? message : '';
        if (data !== null) {
            if (typeof data.responseJSON !== "undefined") {
                let response = data.responseJSON;
                if (response.message) {
                    message = response.message;
                }
            }
            if (typeof data.message !== "undefined") {
                message = data.message;
            }
        }

        if (message !== '') {
            let modalError = $('#modalError');
            modalError.find('.modal-body').html(message.replace('\\n', "<br>"));
            modalError.modal('show');
        }
    },

    displayValidation: function (title, message, options) {
        var options = (typeof options !== 'undefined') ? options : [];
        let modalDom = $('#modalValidation');
        let buttonText = 'Valider';
        let buttonOk = $('<button class="btn btn-normal btn-valider-creation-liste" data-dismiss="modal">' + buttonText + '</button> ');
        let secondButton = undefined;
        modalDom.find('.modal-title').html(title);
        modalDom.find('.block-confirmation__message').html(message);

        if (typeof options['onclick'] !== "undefined") {
            buttonOk.attr('onclick', options['onclick']);
        }
        if (options['secondButton'] !== undefined) {
            $(buttonOk).addClass('m-1');
            secondButton = $('<button class="btn btn-normal no-border m-1" data-dismiss="modal">' + options['secondButton'] + '</button>');
            secondButton.attr('onclick', 'location.href = "' + pap.routing.wishlistsIndex + '";');
        }

        modalDom.find('.modal-footer').html(buttonOk);
        if (secondButton !== undefined) {
            modalDom.find('.modal-footer').append(secondButton);
        }
        modalDom.modal('show');
    },
    displaySessionExpired: function () {
        let modalError = $('#modalError');
        modalError.find('.modal-title').html('Votre session a expiré');
        modalError.find('.modal-body').html('Merci de vous connecter à nouveau.');
        modalError.find('.modal-footer').html(
            '<button class="btn btn-normal" onclick="pap.security.openLogin();">Accéder à la page de connexion</button>'
        );
        modalError.modal('show');
    },

    progressModal: function (modalID, current, max) {
        let modal = $(modalID);
        let progressBar = modal.find('.progress-bar');

        let percentValue = 0;
        if (max > 0) {
            percentValue = Math.round(current * 100 / max);
        }

        if (typeof progressBar !== "undefined") {
            progressBar.prop('aria-valuenow', current);
            progressBar.prop('aria-valuemax', max);
            progressBar.css('width', percentValue + '%');
            progressBar.html(percentValue + '%');
        }
    },
    progressClose: function () {
        let modal = $('#modalPricesOperating');
        let progressBar = modal.find('.progress-bar');

        if (typeof progressBar !== "undefined") {
            progressBar.prop('aria-valuenow', 0);
            progressBar.prop('aria-valuemax', 0);
            progressBar.css('width', 0 + '%');
            progressBar.html(0 + '%');
        }

        setTimeout(function () {
            $('#modalPricesOperating').modal('hide');
        }, 1000);
    },


    displayWishlistCreation: function() {
        pap.modals.add('wishlistCreate', 'createWishlist', {
            id: 'modal-wishlist-creation',
            relatedTarget: 'wishlist-create',
            title: 'Créer une nouvelle liste',
            actions: [
                {
                    class: 'btn-normal no-border d-none',
                    text: 'Annuler',
                    icon: 'icon icon-ic_arrow_next',
                    attr: [
                        {
                            name: 'data-dismiss',
                            value: 'modal'
                        },
                    ]
                },
                {
                    class: 'btn-normal mr-3 d-none',
                    text: 'Créer une liste',
                    icon: 'icon icon-ic_arrow_next',
                    attr: [
                        {
                            name: 'type',
                            value: 'submit'
                        },
                        {
                            name: 'onclick',
                            value: "$('#wishlist-create-form').trigger('submit')",
                        }
                    ],
                }
            ]
        });
        let modalDom = $('#modalWishlistCreation');
        modalDom.modal('show','#wishlist-create');
    },

    displayUnavailableQty: function() {
        pap.modals.add('unavailableQty', '', {
            id: 'modal-unavailable-qty',
            relatedTarget: 'unavailable-qty',
            title: 'Créer une nouvelle liste',
        });

        let modalDom = $('#modalUnavailableQty');
        modalDom.modal('show','#unavailable-qty');
    }
};

function timeRemainingToString(firstTime, nextTime) {
    let diff = nextTime.getTime() - firstTime.getTime();   // now - jan 1

    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours %= 24;
    minutes %= 60;

    let timeRemaining = '';
    let timePart = [
        {
            'type': 'days',
            'value': days,
            'singular': 'jour',
            'plural': 'jours',
        },
        {
            'type': 'hours',
            'value': hours,
            'singular': 'heure',
            'plural': 'heures',
        },
        {
            'type': 'minutes',
            'value': minutes,
            'singular': 'minute',
            'plural': 'minutes',
        }
    ];

    for (let i = 0; i < timePart.length; i++) {
        if (timePart[i].value > 0) {
            let timeType = timePart[i].singular;

            // If already value in remaining time add a blank space to end before add new values
            if (timeRemaining.length > 0) {
                timeRemaining = timeRemaining.concat(' ');

                if (i + 1 === timePart.length) {
                    timeRemaining = timeRemaining.concat('et ');
                }
            }

            // if value is more than ONE, set the plural text
            if (timePart[i].value > 1) {
                timeType = timePart[i].plural;
            }
            timeRemaining = timeRemaining.concat(timePart[i].value + ' ' + timeType);
        }
    }

    return timeRemaining;
}

pap.navAccountEvent = {

    init: function () {
        $("#dropdownUserProfileMobile").click(function () {
            if ($("#accountMenuMobile").hasClass('show')) {
                $("#accountMenuMobile").removeClass('show');
            } else {
                $("#accountMenuMobile").addClass('show');
                $("#dropdownUserProfileMobile").addClass('opned');
                $(".navbar-toggler").removeClass('opned');
            }
            $("#navbarNav").removeClass('show');
            $("#spanNav").removeClass('icon-ic_plus rotate-45');
            $("#spanNav").addClass('icon-ic_burger');

            if ($(".navbar-toggler").length > 0) {
                $(".navbar-toggler").on('click ', function () {
                    $('#accountMenuMobile').removeClass('show');
                });
            }

        });

    }

};

pap.security = {
    modalLogin: $('#modalLogin'),
    modalLoginForget: $('#modalLoginForget'),
    last_username: '',
    init: function () {
        $(document).ready(function () {
            let urlParameters = window.location.toString();
            let urlFilters = urlParameters.split('?')[1];

            $(document).on('click', '#modalLogin button[type="submit"]', function (event) {
                event.preventDefault();
                pap.security.login();
            });
            $(document).on('click', '#modalLogin a.login-forget', function (event) {
                event.preventDefault();
                pap.security.openLoginForget();
            });
            $(document).on('click', '#modalLogin a.login-forget-custom', function (event) {
               
                event.preventDefault();
                pap.security.openLoginForget();
                $('#username-forget').click();
            });
            $(document).on('click', '.userforget-2', function (event) {
                event.preventDefault();
                $('#username-forget').click();
            });
            $(document).on('click', '.login-forget-2', function (event) {
                event.preventDefault();
                $('#password-forget').click();
            });
            $(document).on('click', '.login-forget-3', function (event) {
                event.preventDefault();
                pap.security.openLoginForget();
                $('#password-forget').click();
            });

            $(document).on('change', '#modalLoginForget input[name="forget-data"]', function(event) {
                event.preventDefault();
                $('.security-panel').addClass('d-none');
                let panelIdToShow = $(this).attr('data-target');
                $(panelIdToShow).removeClass('d-none');
            });
            $(document).on('click', '#modalLoginForget form[name="forgot_user_name"] button[type="submit"]', function(event) {
                event.preventDefault();
                pap.security.forgotPasswordAction($(this).closest('form'));
            });

            $(document).on('click', '#modalLoginForget form[name="forgot_password"] button[type="submit"]', function (event) {
                event.preventDefault();
                pap.security.forgotPasswordAction($(this).closest('form'));
            });

            $('.top-user-connexion').on('click', function (event) {
                event.preventDefault();
                pap.security.openLogin();
            });
 
            $('.see-product-detail').click(function (e) {

                let queryParams = $(this).attr('href').split('?')[1];
                let msg = undefined;
                if (queryParams !== undefined) {
                    e.preventDefault();
                    if (queryParams.indexOf('connexion') >= 0 &&
                        queryParams.indexOf('produit') >= 0
                    ) {
                        msg = 'Connectez-vous pour profiter de l’ensemble des fonctionnalités du site';
                    }
                    pap.security.openLogin(msg);
                    if (queryParams.indexOf('produit') >= 0) {
                        let productDetails = queryParams.split('&')[1];
                        let productSlug = productDetails.split('=')[1];
                        setTimeout(function () {
                            $('#_target_path').val('/espace-client/assortiment/produit/' + productSlug);
                        }, 500);
                    }
                }
            });

            $('.see-product-detail').click(function (e) {
                let queryParams = $(this).attr('href').split('?')[1];
                let msg = undefined;
                if (queryParams !== undefined) {
                    e.preventDefault();
                    if (queryParams.indexOf('connexion') >= 0 &&
                        queryParams.indexOf('produit') >= 0
                    ) {
                        msg = 'Connectez-vous pour profiter de l’ensemble des fonctionnalités du site';
                    }
                    pap.security.openLogin(msg);
                    if (queryParams.indexOf('produit') >= 0) {
                        let productDetails = queryParams.split('&')[1];
                        let productSlug = productDetails.split('=')[1];
                        setTimeout(function () {
                            $('#_target_path').val('/espace-client/assortiment/produit/' + productSlug);
                        }, 500);
                    }
                }
            });

            // Detect if connexion is ask
            if (typeof urlFilters !== "undefined" && urlFilters.indexOf('connexion') >= 0) {
                $('.top-user-connexion').trigger('click');
                //imporant pour le temps que le formulaire soit affiché
                if (urlFilters.indexOf('produit') >= 0) {
                    let productDetails = urlFilters.split('&')[1];
                    let productSlug = productDetails.split('=')[1];
                    setTimeout(function () {
                        $('#_target_path').val('/espace-client/assortiment/produit/' + productSlug);
                    }, 500);
                }
            }
        });
    },
    openLogin: function (descriptionMsg) {
        pap.security.generateLoginForm();
        let descriptionContent = pap.security.modalLogin.find('p.modal-desc');
        descriptionContent.removeClass('hidden');
        descriptionContent.text(descriptionMsg);

        $('.modal.show').modal('hide');
        setTimeout(function () {
            pap.security.modalLogin.modal('show');
        }, 500);

    },
    openLoginForget: function () {
        pap.security.generateLoginForgetForms();
        $('.modal.show').modal('hide');
        setTimeout(function () {
            pap.security.modalLoginForget.modal(
                {
                    'show': true
                }
            );
        }, 500);
    },
    login: function () {
        let modalLogin = pap.security.modalLogin;
        let callAjax = pap.ajax;

        let validationRedirect = modalLogin.find('input[name="_target_path"]').prop('value');

        if (window.location.toString().indexOf('espace-client') > 0) {
            validationRedirect = window.location.toString();
        }
        if (window.location.toString().indexOf('/catalogue/') > 0 && window.location.toString().indexOf('&produit') > 0) {
            validationRedirect = window.location.toString();
        }

        callAjax.url = pap.routing.cartSecurityLogin;
        callAjax.method = 'POST';
        callAjax.dataType = 'json';
        callAjax.data = {
            '_username': modalLogin.find('#username').prop('value'),
            '_password': modalLogin.find('#password').prop('value'),
            '_remember_me': modalLogin.find('#remember_me').prop('value'),
            '_target_path': validationRedirect,
            '_csrf_token': modalLogin.find('input[name="_csrf_token"]').prop('value')
        };

        callAjax.onSuccess = function (data) {

            // Redirect to route
            if (typeof data['status_code'] !== "undefined" && typeof data['status_text'] !== "undefined" && typeof data['uri'] !== "undefined") {
                if (data['status_text'] === 'Found' && data['status_code'] === 302) {

                    if (window['google_tag_manager']) {
                            gtag('event', 'login', {
                                'method': 'Formulaire site pro à pro',
                                'username': modalLogin.find('#username').prop('value'),
                                'event_callback': function() {
                                    window.location.replace(validationRedirect);
                                  }
                            });                                       
                    } else {
                        window.location.replace(validationRedirect);
                        return
                    }
                }
            }

            // Display error message
            if (typeof data['status_code'] !== "undefined" && data['status_code'] === "KO") {
                if (typeof data.message !== "undefined") {
                    let errorMessageDom = $('<div class="block-messages__content alert"><p></p><a class="close-alert"><span class="icon icon-ic_plus"></span></a></div>');
                    errorMessageDom.find('p').text(data['message']);
                    modalLogin.find('.block-messages').html(errorMessageDom);
                }

                // Reload Form
                pap.security.generateLoginForm();
            }
        };
        callAjax.onError = function (data) {
            return false;
        };

        callAjax.execute();
    },

    forgotPasswordAction: function (form) {
        let modal = pap.security.modalLoginForget;
        let callAjax = pap.ajax;

        callAjax.url = form.attr('action');
        callAjax.method = 'POST';
        callAjax.dataType = 'json';
        callAjax.data = form.serialize();

        callAjax.onSuccess = function (data) {
            let errorMessageDom = $('<div class="block-messages__content alert"><p></p><a class="close-alert"><span class="icon icon-ic_plus"></span></a></div>');
            modal.find('.block-messages .alert').remove();
            // Redirect to route
            if (typeof data['status_code'] !== "undefined" && typeof data['status_text'] !== "undefined") {
                if (typeof data['uri'] !== "undefined") {
                    window.location.replace(data['uri']);
                    return;
                }

                if (data['status_code'] === "KO") {
                    errorMessageDom.find('p').text(data['status_text']);
                    modal.find('.block-messages').append(errorMessageDom);
                }
                if (data['status_code'] === "OK") {
                    $('.modal.show').modal('hide');
                    setTimeout(function () {
                        pap.modals.displayValidation('Mail envoyé', data['status_text']);
                    }, 500);
                }
            }
            pap.security.renderLoginForm(data['data']);
        };
        callAjax.onError = function (data) {
        };
        callAjax.execute();
    },


    generateLoginForm: function () {
        let callAjax = pap.ajax;

        callAjax.url = pap.routing.cartSecurityLoginRender;
        callAjax.method = 'GET';
        callAjax.dataType = 'html';
        callAjax.onSuccess = function (data) {
            pap.security.modalLogin.find('form').html(data);
            pap.input.trigger(pap.security.modalLogin);
        };
        callAjax.onError = function (data) {
            console.log(data);
        };
        callAjax.execute();
    },
    generateLoginForgetForms: function () {
        let callAjax = pap.ajax;

        callAjax.url = pap.routing.cartSecurityLoginForgetRender;
        callAjax.method = 'GET';
        callAjax.dataType = 'html';
        callAjax.onSuccess = function (data) {
            pap.security.renderLoginForm(data);
        };
        callAjax.onError = function (data) {
            console.log(data);
        };
        callAjax.execute();
    },
    renderLoginForm: function (data) {
        let formPasswordForgot = pap.security.modalLoginForget.find('form[name="forgot_password"]');
        let formUserForgot = pap.security.modalLoginForget.find('form[name="forgot_user_name"]');

        formPasswordForgot.html($(data).find('form[name="forgot_password"]').html());
        formUserForgot.html($(data).find('form[name="forgot_user_name"]').html());
        pap.input.trigger(pap.security.modalLoginForget);

        let affectToOrderDOM = $('#modalLoginForget input[name="forget-data"][value="password"]');
        affectToOrderDOM.prop('checked', 'checked');
        if (affectToOrderDOM.length > 0) {
            affectToOrderDOM.trigger('change');
        }
    },
};

pap.selection = {
    modal: $('#modalProductDetail'),
    init: function () {
        $(document).ready(function () {
            $(document).on('click', '#modalProductDetail .see-product-detail', function (event) {
                pap.selection.openProduct(event);
            });
        });
    },
    openModal: function (selectionItem) {
        let tagsHtml = this.modal.find('.block-product-details__image_promo');

        let title = $(selectionItem).find('.selection-item__desc .product-title').text();
        let brand = $(selectionItem).find('.selection-item__desc .product-brand').text();
        let picture = $(selectionItem).find('.selection-item__image').html();
        let reference = $(selectionItem).find('.selection-item__desc .product-reference').text();
        let description = $(selectionItem).find('.selection-item__desc .product-description').text();
        let productUrl = $(selectionItem).find('.product-discover').attr('data-href');
        let novelty = parseInt($(selectionItem).find('.selection-item__desc .product-novelty').text());
        let promo = 0;

        // Add tags content
        tagsHtml.html('');
        if (novelty === 1) {
            tagsHtml.prepend($('<span></span>').addClass('new').text('Nouveau'));
        }
        if (promo === 1) {
            tagsHtml.prepend($('<span></span>').addClass('promo').text('Promotion'));
        }

        // Change modal content
        this.modal.find('.modal-title').text(title);
        this.modal.find('.product-brand').text(brand);
        this.modal.find('.product-reference').text(reference);
        this.modal.find('.product-description').text(description);
        this.modal.find('.block-product-details__image_img').html(picture);
        this.modal.find('a').attr('href', productUrl);

        // Display modal
        this.modal.modal('show');
    },
    openProduct: function (event) {
        // Check if user is not connected
        if ($('.top-user-connexion').length === 1) {
            event.preventDefault();
            pap.security.openLogin();
            return false;
        }
    }
};

pap.productNotAvailable = {
    openModal: function () {
        let modalError = $('#modalError');
        modalError.find('.modal-title').html('Vous ne pouvez pas commander ce produit');
        modalError.find('.modal-body').html("Merci de contacter votre représentant");
        modalError.find('.modal-footer button').attr('onclick', 'window.location.replace(pap.routing.customerAccount);');
        modalError.modal('show');
    }
    };

pap.checkCounterIsNumeric = function () {
    $(document).on('keypress', 'input[name="counter"]', function (event) {
        return $.isNumeric(event.key);
    });

    $(document).on('focusout', 'input[name="counter"]', function () {
        if(isNaN($(this).val())){
            $(this).val(0);
        }
    });
};

pap.datetimeToTimestamp = function (datetime) {
    let regDatetime = /^[0-9]{4}-(?:[0]?[0-9]{1}|10|11|12)-(?:[012]?[0-9]{1}|30|31)(?: (?:[01]?[0-9]{1}|20|21|22|23)(?::[0-5]?[0-9]{1})?(?::[0-5]?[0-9]{1})?)?$/;
    if(regDatetime.test(datetime) === false)
        throw("Wrong format for the param. `Y-m-d H:i:s` expected.");

    let a=datetime.split(" ");
    let d=a[0].split("-");
    let t=a[1].split(":");

    let date = new Date();
    date.setFullYear(d[0],(d[1]-1),d[2]);
    date.setHours(t[0],t[1],t[2], 0);

    return date.getTime();
};
pap.elasticSearch = function(
    url,
    searchInput,
    resultList,
    closestParentSelector,
    submitBtn,
    keyupDelay,
    resultProperty,
    dataAttributeProperty
) {

    // url to request
    this.url = url;
    // the ul tag that will contains the search result
    this.searchInput = searchInput;
    // the ul tag that will contains the search result
    this.resultList = resultList;
    // allow to dismiss the result list when clicking outside of a certain area.
    this.closestParentSelector = closestParentSelector;
    this.submitBtn = submitBtn;
    // the delay (in ms) before fetching results
    this.keyupDelay = keyupDelay;
    // the property that contains actual data
    this.resultProperty = resultProperty;
    this.dataAttributeProperty = dataAttributeProperty;

    this.resultList.classList.add('d-none');
    let self = this;
    this.searchInput.addEventListener(
        'keyup',
        this.debounce(function(e) {
            let text = e.target.value;
            if (text.length < 3) {
                self.removeAllChild(self.resultList);
                return;
            }
            self.removeAllChild(self.resultList);
            self.search(text);
        }, self.keyupDelay)
    );

    // delete the result ul content when clicking outside of closestParentSelector
    document.addEventListener('click', function(e) {
        if ($(e.target).closest(self.closestParentSelector).length === 0) {
            self.removeAllChild(self.resultList);
            self.resultList.classList.add('d-none');
        }
    });

    this.resultList.addEventListener('click', function(e) {
        if (e.target && e.target.nodeName === 'LI') {
            self.searchInput.value = e.target.getAttribute('data-' + self.dataAttributeProperty);
            self.submitBtn.click();
        }
    });
};


pap.elasticSearch.prototype.search = function(query) {
    let self = this;
    let ajax = pap.ajax;
    ajax.url = this.url;
    ajax.data = {};
    ajax.data.search = query;
    ajax.data.limit = 6;
    ajax.data.page = 1;
    ajax.dataType = 'json';
    ajax.method = 'GET';
    ajax.onSuccess = function(data) {
        self.showResult(query, data, self.resultProperty);
    };
    ajax.onError = function(data) {
        if (data.responseJSON !== undefined) {
            let error = data.responseJSON.error;
            if (error === 'no customer') {
                pap.customers.displayCustomerSelection(function () {
                    pap.customers.saveCustomerAgencySession({
                        'customerId': pap.customers.current,
                        'agencyId': pap.customers.currentAgency,
                        'cartId': pap.cart.id,
                        'action': 'search'
                    });
                })
            }
        }
    };
    ajax.execute();
};

pap.elasticSearch.prototype.showResult = function(query, data) {
    let self = this;
    if (data[self.resultProperty].length === 0) {
        self.resultList.classList.add('d-none');
        return;
    }
    data[self.resultProperty].forEach(function(product) {
        let regex = new RegExp(query, 'gi');
        let replacement = '<strong class="color-primary">$&</strong>';
        let liElement = document.createElement('li');
        let liHtmlContent = '';

        for (let property in product) {
            if (product.hasOwnProperty(property)) {
                liElement.setAttribute(
                    'data-' + self.dataAttributeProperty,
                    product[self.dataAttributeProperty]
                );
                if (product[property] !== null) {
                    liHtmlContent +=
                        product[property].replace(regex, replacement) + '<br>';
                }
            }
        }

        liElement.innerHTML = liHtmlContent;
        self.resultList.appendChild(liElement);
        self.resultList.classList.remove('d-none');
    });
};
// add a delay before fetching results
pap.elasticSearch.prototype.debounce = function(callback, delay) {
    let timer;
    return function() {
        let args = arguments;
        let context = this;
        clearTimeout(timer);
        timer = setTimeout(function() {
            callback.apply(context, args);
        }, delay);
    };
};
// clear previous result
pap.elasticSearch.prototype.removeAllChild = function(el) {
    while (el.firstChild) {
        el.removeChild(el.firstChild);
    }
};
pap.elasticSearch.prototype.getUrlParams = function () {
    let urlParams = {};
    if (window.location.search.length > 1) {
        for (let aItKey, nKeyId = 0, aCouples = window.location.search.substr(1).split("&"); nKeyId < aCouples.length; nKeyId++) {
            aItKey = aCouples[nKeyId].split("=");
            urlParams[unescape(aItKey[0])] = aItKey.length > 1 ? unescape(aItKey[1]) : "";
        }
    }
    return urlParams;
};

pap.globalSearch = function (query) {
    let searchMatchesNodes = document.querySelectorAll('.page-content-overview');
    let searchMatches = Array.apply(null, searchMatchesNodes);
    searchMatches.forEach(function(searchMatch) {
        let regex = new RegExp(query, 'gi');
        let replacement = '<strong class="color-primary">$&</strong>';
        searchMatch.innerHTML = searchMatch.innerHTML.replace(regex, replacement);
    });

    // Match link's hash to tab's one
    let url = document.location.toString();
    if (url.match('#')) {
        let hash = url.split('#')[1];
        $('.nav-tabs a[href="#' + hash + '"]').tab('show');
        this.addHashToPagination(hash);
    }

    let self = this;
    // Change hash for page reload
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        let x = window.scrollX;
        let y = window.scrollY;
        window.location.hash = e.target.hash;
        window.scrollTo(x,y);
        let hash = e.target.hash ? e.target.hash : '';
        self.addHashToPagination(hash.split('#')[1]);
    });
};

pap.globalSearch.prototype.addHashToPagination = function(hash) {
    let a = '#' + hash + ' li.paginate_button.page-item a';
    $(a).each(function () {
        let $oldUrl = $(this).attr('href');
        let re = new RegExp('#' + hash);
        if (!re.test($oldUrl)) {
            $(this).attr('href', $oldUrl + '#' + hash);
        }
    });
};

pap.wishlist = {
    modalDom: $('#modalWishlistCreation'),
    /** Display view **/
    viewMode: '',
    viewList:       'list',
    viewDetail:     'detail',
    viewSelector:   'selector',
    /** Mode of the modal **/
    modalMode: '',
    modeCreate:     'create',
    modeEdit:       'edit',
    modeDuplicate:  'duplicate',
    productsToAdd: [],
    addToListInit: false,

    lists: {
        init: function() {
            pap.wishlist.viewMode = pap.wishlist.viewList;
            pap.wishlist.init();

            // Trigger Event for wishlist creation
            $(document).on('click', '#wishlist-create', function () {
                pap.wishlist.modalMode = pap.wishlist.modeCreate;
                pap.wishlist.prepareCreationForm({});
                pap.modals.displayWishlistCreation();
            });

            // Make the modal confirmation for deletion
            $('button[id^=delete-wishlist-]').each(function () {
                let wishlistName = $(this).closest('tr').find('.wishlist-name').text();
                pap.wishlist.triggerDeleteWishlist($(this).attr('id'), wishlistName);
            });


            let urlParameters = pap.customFilters.getUrlParams();
            if (urlParameters['cartConfirmation'] !== undefined) {
                let options = [];
                options['onclick'] = '$(this).closest(".modal").modal("hide");';
                pap.wishlist.displayCartAddItemValidation(urlParameters['cartConfirmation'], options);
                history.replaceState({}, document.title, window.location.pathname);
            }

            $(document).on('click', '.wishlist-add-all-products-to-basket', function () {
                pap.wishlist.addAllProducsToBasket($(this).data('cart-id'),$(this).data('wishlist-id'));
            });



        }
    },
    detail: {
        init: function() {
            pap.wishlist.viewMode = pap.wishlist.viewDetail;
            pap.wishlist.init();
            pap.wishlist.detail.initZeroQtyModal();
            $(document).on('click', '.wishlist-delete-item', function () {
                let productName = $(this).closest('.wishlist-item').find('.cart-item-title').text();
                pap.modals.add('confirm', 'deleteWishlistItem', {
                    id: 'modal-confirmation',
                    relatedTarget: $(this).attr('id'),
                    title: '',
                    content: 'Vous êtes sur le point de supprimer un article de votre liste personnelle <br/>Attention cette  opération est irréversible. <br/><br/>Confirmez-vous la suppression du produit <strong>' + productName + '</strong> ?',
                    actions: [
                        // {
                        //     class: 'btn-normal',
                        //     text: 'Ne pas supprimer',
                        //     icon: '',
                        //     attr: [
                        //         {
                        //             name: 'data-dismiss',
                        //             value: 'modal'
                        //         },
                        //     ]
                        // },
                        {
                            class: 'btn-normal btn-valider',
                            text: 'Valider',
                            icon: '',
                            attr: [
                                {
                                    name: 'onclick',
                                    value: 'pap.modals.doRelatedSubmit("' + $(this).attr('id') + '")'
                                },
                                {
                                    name: 'data-dismiss',
                                    value: 'modal'
                                },
                            ],
                        }
                    ]
                });
                $('#modalConfirmation').modal('show', '#' + $(this).attr('id'));
            });


            $(document).on('click', '#wishlist-edit', function () {
                pap.wishlist.modalMode = pap.wishlist.modeEdit;
                let str = $(this).attr('data-wishlist');
                let data = JSON.parse(str);
                pap.wishlist.edit(data);
            });
            $(document).on('click', '#wishlist-duplicate', function () {
                pap.wishlist.modalMode = pap.wishlist.modeDuplicate;
                let str = $(this).attr('data-wishlist');
                let data = JSON.parse(str);
                pap.wishlist.duplicate(data);
            });

            $(document).on('click', '.wishlist-add-all-products-to-basket', function () {
                pap.wishlist.addAllProducsToBasket($(this).data('cart-id'),$(this).data('wishlist-id'));
            });


            // Make the modal confirmation for deletion
            $('button[id^=wishlist-delete]').each(function () {
                let wishlistName = $(this).closest('.wishlist-detail').find('.wishlist-name').text();
                pap.wishlist.triggerDeleteWishlist($(this).attr('id'), wishlistName);
            });
        },
        deleteSelected: function(temperature) {
            let itemsSelected = [];
            let selectedCheckbox = $('*[data-temperature="' + temperature + '"] input[name="check_1"]:checked');
            selectedCheckbox.each(function () {
                let itemRow = $(this).closest('.basket-item');
                itemsSelected.push(itemRow.attr('data-item'));
            });
            pap.wishlist.deleteWishlistItem(itemsSelected);
        },
        initZeroQtyModal: function() {
            let modalQtyConfirmation = $('#modalProductQtyConfirmation');
            pap.modals.add('wishlistZeroQty', 'fromWishlistToOrder', {
                id: 'modal-product-qty-confirmation',
                relatedTarget: 'wishlist-to-order',
                title: 'Sélection quantité',
                actions: [
                    {
                        class: 'btn-normal no-border',
                        text: 'Annuler',
                        icon: 'icon icon-ic_arrow_next',
                        attr: [
                            {
                                name: 'data-dismiss',
                                value: 'modal'
                            },
                        ]
                    },
                    {
                        class: 'btn-normal mr-3',
                        text: 'Valider',
                        icon: 'icon icon-ic_arrow_next',
                        attr: [
                            {
                                name: 'type',
                                value: 'button'
                            },
                            {
                                name: 'id',
                                value: 'validate-wishlist-zero-qty'
                            }
                        ],
                    }
                ]
            });
            modalQtyConfirmation.find('.modal-desc').html('Veuillez saisir la quantité souhaitée');
            modalQtyConfirmation.find('.modal-body').removeClass('hidden').removeAttr('style');
        },
        
    },
    addTolist: {
        init: function () {
            pap.wishlist.viewMode = pap.wishlist.viewSelector;
            pap.wishlist.init();

            $(document).on('click', '.wishlist-create', function (event) {
                event.preventDefault();

                pap.wishlist.modalMode = pap.wishlist.modeCreate;
                let productId = $('#productIdCustom').html();
                pap.wishlist.prepareCreationForm({},productId);
                pap.modals.displayWishlistCreation();

            });

                $(document).on('click', '.add-to-wishlist-submit', function (event) {

                    event.preventDefault();
                    let wishlistId = $('#result').html();
                    let productId = $('#productIdCustom').html();
                    pap.wishlist.addProductsToWishlist({
                                wishlistId: wishlistId,
                                productId: productId
                            })
                        
                    
                });

        },
        refreshList: function () {
            let ajax = pap.ajax;
            ajax.url = pap.routing.wishlistsSelectorRefreshList;
            ajax.dataType = 'html';
            ajax.method = 'POST';
            ajax.data = {};
            ajax.onSuccess = function (data) {
                $('.product-wishlist-info .wishlists-container').html(data);
            };
            ajax.onError = function(data) {
                let error = data.responseJSON.message;
                $('.modal.show').modal('hide');
                setTimeout(function () {
                    pap.modals.displayError(null, error);
                }, 500);
            };
            ajax.execute();
        },
    },
    init: function() {
        $(document).on('submit', '#wishlist-create-form', function(e) {
            e.preventDefault();

            let name    = pap.wishlist.modalDom.find('#wishlist_name').val();
            let oldName = pap.wishlist.modalDom.find('#wishlist_old_name').val();
            let notes   = pap.wishlist.modalDom.find('#wishlist_notes').val();
            let mode    = pap.wishlist.modalDom.find('#wishlist_mode').val();
            let productId    = pap.wishlist.modalDom.find('#product_id').val();

            let wishlistData = {name: name, oldName: oldName, notes: notes, mode: mode , productId: productId};
            pap.wishlist.addWishlist(wishlistData);
            
        });
    },

    updateWishlistItemQty: function(itemRow) {
        let wishlistItem = itemRow.find('input[name="wishlist-item-id"]').prop('value');
        if (wishlistItem !== undefined) {
            let qty = parseFloat(itemRow.find('input[name="counter"]').prop('value'));
            let ajax = pap.ajax;
            ajax.url = pap.routing.updateWishlistItemQty;
            ajax.dataType = 'json';
            ajax.method = 'PUT';
            ajax.data = {
                id: wishlistItem,
                qty: qty
            };
            ajax.onSuccess = function (data) {
            };
            ajax.execute();
        }
    },
    deleteWishlistItem: function (wishlistItemIds) {
        let ajax = pap.ajax;
        ajax.url = pap.routing.deleteWishlistItem;
        ajax.dataType = 'json';
        ajax.method = 'DELETE';
        ajax.data = {
            ids: wishlistItemIds,

        };
        ajax.onSuccess = function (data) {
            window.location.reload();
        };
        ajax.execute();
    },

    addAllProducsToBasket: function(cartId,wishlistId) {

        let ajax = pap.ajax;
        ajax.url = pap.routing.wishlistAddAllItemsToBasket;
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.data = {
            wishlistId: wishlistId, 
            cartId: cartId
        };

        ajax.execute();

    },


    addWishlist: function (data) {
        let name = data.name;
        let oldName = data.oldName;
        let notes = data.notes;
        let mode = pap.wishlist.modalMode;
        let productId = data.productId;


        if ((typeof mode === 'undefined') || mode === '') {
            pap.modals.displayError(null, 'Une erreur est survenue, le mode est inconu');
            return false;
        }

        if (name === undefined) {
            return;
        }
        let ajax = pap.ajax;
        ajax.url = pap.routing.createWishlist;
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.data = {
            name: name, 
            oldName: oldName,
            notes: notes,
            mode: mode
        };
        ajax.onSuccess = function (data) {
            if (typeof data.message !== 'undefined') {
                pap.wishlist.modalDom.modal('hide');

                setTimeout(function () {
                    pap.modals.displayError(null, data.message);
                }, 500);
            }
            if (typeof data.wishlist !== 'undefined') {
                let options = [];
                let content = '';
                let validationTitle = '';

                pap.wishlist.modalDom.modal('hide');

                if (productId) {
                    pap.wishlist.addProductsToWishlist({
                        wishlistId: data.wishlist.id,
                        productId: productId
                    });
                   

                } else {

                switch (mode) {
                    case pap.wishlist.modeCreate:                      
                        content =  'Votre liste ' + data.wishlist.name + ' a été créée';
                        options['onclick'] = 'document.location.reload(true);';
                        if (pap.wishlist.viewMode === pap.wishlist.viewSelector) {
                            options['onclick'] = 'pap.wishlist.addTolist.refreshList();';
                        }
                        validationTitle = 'Liste personnelle créée';
                        break;
                    case pap.wishlist.modeEdit:
                        content = 'Votre liste a été modifiée';
                        options['onclick'] = 'document.location.href = "' + data.wishlist.url + '";';
                        validationTitle = 'Liste personnelle modifiée';
                        break;
                    case pap.wishlist.modeDuplicate:
                        content = 'Votre liste a été dupliquée';
                        validationTitle = 'Liste personnelle dupliquée'
                }
            }

                 pap.modals.displayValidation(validationTitle, content, options);
                $('#exampleModalCenter').modal('hide');
            }
        };
        ajax.onError = function(data) {
            let error = '';
            if (data.responseJSON !== undefined && data.responseJSON.message !== undefined) {
                error = data.responseJSON.message;
            } else if (data.message !== undefined) {
                error = data.message;
            } else {
                error = 'Une erreur inconnue est survenue pendant le processus.';
            }

            $('.modal.show').modal('hide');
            setTimeout(function () {
                pap.modals.displayError(null, error);
            }, 500);
        };
        ajax.execute();
    },
    prepareCreationForm: function(data , productId) {
        if (typeof data === 'undefined') {
            var data = {};
        }



        let name = typeof data.name != 'undefined' ? data.name : '';
        let oldName = typeof data.oldName != 'undefined' ? data.oldName : '';
        let notes = typeof data.notes != 'undefined' ? data.notes : '';
        let mode = typeof data.mode != 'undefined' ? data.mode : '';


        $('#wishlist_name').val(name);
        $('#wishlist_old_name').val(oldName);
        $('#wishlist_notes').val(notes);
        $('#wishlist_mode').val(mode);
        $('#product_id').val(productId);
       
    },
    duplicate: function(data) {
        data.mode = pap.wishlist.modeDuplicate;
        data.oldName = data.name;

        pap.wishlist.modalMode = pap.wishlist.modeDuplicate;
        pap.wishlist.prepareCreationForm(data);
        pap.modals.add('wishlistCreate', 'duplicateWishlist', {
            id: 'modal-wishlist-creation',
            relatedTarget: 'wishlist-edit',
            title: 'Duplication d\'une liste',
            actions: [
                {
                    class: 'btn-normal no-border',
                    text: 'Annuler',
                    icon: 'icon icon-ic_arrow_next',
                    attr: [
                        {
                            name: 'data-dismiss',
                            value: 'modal'
                        },
                    ]
                },
                {
                    class: 'btn-normal mr-3',
                    text: 'Dupliquer la liste',
                    icon: 'icon icon-ic_arrow_next',
                    attr: [
                        {
                            name: 'type',
                            value: 'button'
                        },
                        {
                            name: 'onclick',
                            value: "$('#wishlist-create-form').trigger('submit')",
                        }
                    ],
                }
            ]
        });
        $('#modalWishlistCreation').modal('show', '#' + 'wishlist-edit');
    },
    edit: function(data) {
        data.mode = pap.wishlist.modeEdit;
        data.oldName = data.name;

        pap.wishlist.modalMode = pap.wishlist.modeEdit;
        pap.wishlist.prepareCreationForm(data);
        pap.modals.add('wishlistCreate', 'editWishlist', {
            id: 'modal-wishlist-creation',
            relatedTarget: 'wishlist-edit',
            title: 'Modification d\'une liste',
            actions: [
                {
                    class: 'btn-normal no-border',
                    text: 'Annuler',
                    icon: 'icon icon-ic_arrow_next',
                    attr: [
                        {
                            name: 'data-dismiss',
                            value: 'modal'
                        },
                    ]
                },
                {
                    class: 'btn-normal mr-3',
                    text: 'Modifier la liste',
                    icon: 'icon icon-ic_arrow_next',
                    attr: [
                        {
                            name: 'type',
                            value: 'button'
                        },
                        {
                            name: 'onclick',
                            value: "$('#wishlist-create-form').trigger('submit')",
                        },
                        {
                            name: 'data-dismiss',
                            value: 'modal'
                        }
                    ],
                }
            ]
        });
        $('#modalWishlistCreation').modal('show', '#' + 'wishlist-edit');
    },
    triggerDeleteWishlist: function (relatedTarget, wihlistName) {
        pap.modals.add('confirm', 'deleteWishlist', {
            id: 'modal-confirmation',
            relatedTarget: relatedTarget,
            title: '',
            content: 'Etes-vous sur de vouloir supprimer cette liste personnelle  <strong>' + wihlistName + '</strong> ?',
            actions: [
                // {
                //     class: 'btn-normal',
                //     text: 'Ne pas supprimer',
                //     icon: '',
                //     attr: [
                //         {
                //             name: 'data-dismiss',
                //             value: 'modal'
                //         },
                //     ]
                // },
                {
                    class: 'btn-normal btn-valider',
                    text: 'Valider',
                    icon: '',
                    attr: [
                        {
                            name: 'onclick',
                            value: 'pap.modals.doRelatedSubmit("' + relatedTarget + '")'
                        }
                    ],
                }
            ]
        });
    },
    deleteWishlist: function (id) {
        let url = pap.routing.wishlistDelete.replace('0', id);
        document.location.href = url + document.location.search;
    },
    addProductsToWishlist: function (data) {
        let wishlistId = data.wishlistId;
        let productId = data.productId;


        if (!wishlistId) {
            setTimeout(function () {
                pap.wishlist.modalDom.modal('hide');
                pap.modals.displayError(null, "Aucune liste n'a été selectionnée");
            }, 500);
            return;
        }

        if (!productId ) {
            setTimeout(function () {
                pap.modals.displayError(null, "");
            }, 500);
            return;
        }

        let ajax = pap.ajax;
        ajax.url = pap.routing.addToWishlist;
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.data = {
            wishlistId: wishlistId,
            productId: productId
        };
        ajax.onSuccess = function (data) {
            if (typeof data.message !== 'undefined') {
                pap.wishlist.modalDom.modal('hide');

                setTimeout(function () {
                    pap.modals.displayError(null, data.message);
                }, 500);
            }
            if (typeof data.wishlist !== 'undefined') {
                let options = [];
                options['onclick'] = 'pap.wishlist.addTolist.refreshList();';
                pap.wishlist.modalDom.modal('hide');
                $('#exampleModalCenter').modal('hide');

                if (window['google_tag_manager']) {

                    gtag("event", "add_to_wishlist", {
                        currency: "EUR",
                        items: [{
                            item_id: $('#productCodeCustom').html(),
                            item_name: $('#productNameCustom').html(),
                        }]
                      });
        
                 }

                 pap.modals.displayValidation(
                     'Confirmation d\'ajout à la liste personnelle',
                    'le(s) produit(s) ont bien été ajouté à la liste personnelle <b>' + data.wishlist.wishlistName + '</b>',
                     options
                 );
                 
            }
        };
        ajax.onError = function (data) {
            let error = data.responseJSON.message;
            $('.modal.show').modal('hide');
            setTimeout(function () {
                pap.modals.displayError(null, error);
            }, 500);
        };

        ajax.execute();
    },
    getCartItemsContent: function(products) {
        let ajax = pap.ajax;
        ajax.url = pap.routing.wishlistCartItemsContent;
        ajax.dataType = 'json';
        ajax.method = 'POST';
        ajax.data = {
            products: products
        };
        ajax.onSuccess = function (data) {
            return data;
        };
        ajax.execute();
    },
    setQtyModalValidation: function (modalQtyConfirmation, validateBtnSelector) {
        modalQtyConfirmation.find('.basket-item').each(function (index, item) {
            let qty  = $(item).find('input[name="counter"]').prop('value');
            qty = parseFloat(qty);
            if (qty <= 0) {
                $(item).find('.valid-qty').removeClass('d-none');
                modalQtyConfirmation.find(validateBtnSelector).addClass('disabled');
            }
        });
        modalQtyConfirmation.find('input[name="counter"]').on('change', function (e) {
            modalQtyConfirmation.find(validateBtnSelector).removeClass('disabled');
            modalQtyConfirmation.find('.basket-item').each(function (index, item) {
                let qty  = $(item).find('input[name="counter"]').prop('value');
                qty = parseFloat(qty);
                if (qty <= 0) {
                    $(item).find('.valid-qty').removeClass('d-none');
                    modalQtyConfirmation.find(validateBtnSelector).addClass('disabled');
                } else {
                    $(item).find('.valid-qty').addClass('d-none');
                }
            });
        });
    },
    displayCartAddItemValidation : function (temperature, options) {
        let content = 'Les produits <strong>' + temperature.toUpperCase() + '</strong> ont bien été ajoutés à votre commande';
        pap.modals.displayValidation('Produits ajoutés', content, options);
    }
};
//block liste articles
// $('.button-article').click(function(){
//     $('.article-list').removeClass('article-hidden').addClass('article-full');
// });

$('.button-article').click(function() {
    // $("#article-list").load(location.href + " #article-list");
    $('.grid1').addClass('d-none');
    $('.grid2').removeClass('d-none');
    if( $('.grid').length )         // use this if you are using id to check
    {
        $('.grid').masonry({
            itemSelector: '.grid-item',
            fitWidth: true,
          });
    }

});


$('.button-article').click(function(){
    // $('.grid-masonry-close').addClass('d-none');
    $('.grid-masonry-open').removeClass('invisible').addClass('visible');
});
//block liste articles
$('.lien').click(function(){
    $('.lien').css("border-color", "#1F2329");
    $(this).css("border-color", "#FF8A8A" );
});


$('.nav-item').click(function(){
    var $this = $(this);
    $('a[data-toggle=tab]').each(function () {
        $this.on('shown.bs.tab', function () {

            if( $('.grid').length )         // use this if you are using id to check
            {
                $('.grid').masonry({
                    itemSelector: '.grid-item',
                    fitWidth: true,
                  });
            }
});
});


});

$('.close-menu-burger').click(function(){
    $('#spanNav').click();
  
});

$(document).on('blur','input[name=quantity]', function(e) {
    let basket = $(this).closest('.basket-item');
    let original_counter = basket.find('input[name=counter]');

    if(e.currentTarget.value < 1) {
        let del = basket.parent().find('.cart-delete-item');
        del.click();
        return;

    }

    original_counter.val(e.currentTarget.value).trigger('change');
})

$(document).on('click','.counter-remaining .plus, .counter-remaining .minus', function(e) {


});

$(document).on('click','#confirm-add-cart', function(e) {
    let qty = $(e.target).parent().parent().parent().find('input[name=counter-remaining]').val();
    let itemId = $(e.target).closest('.basket-item').find('input[name=item-id]').prop('value');
    pap.cart.itemRemainingAdd(
        pap.routing.basketCartRemainingItemAdd,
        itemId,
        parseFloat(qty).toFixed(2)
    );
});



                //detail liste => ajout tout les produits à une commande
                $('.ajout-panier-liste').on('click', function(e) {
                    e.preventDefault();
                    $('.select-all').click();
                    $('.wishlist-add-selected-to-order').click();
                
                });


                // mouseenter()



                $('.commande-en-cours-mobile').on('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();           
                    if ($('.mobile-info-basket').hasClass('open')) {
                      $('.mobile-info-basket').removeClass('open').addClass('d-none');
                    } else {
                      $('.mobile-info-basket').addClass('open').removeClass('d-none');
                    }
                  });
                  
                                  
       

                //pagination listes-perso
                $(document).ready(function (e) {
                if (window.location.search === "?page=2"){
                    $('.previous').find('.page-link').css('color', '#1C1E20');
                    $('.previous').find('.page-link').css('opacity', '1');
                }
            });


              $('.button-filter-mobil').on('click', function () {
                    $('.filter-mobile').removeClass('d-none');
                    $('.button-filter-mobil').addClass('filter-mobile-open')

                });

                $('.filter-mobile-open').on('click', function () {
                  
                    
                    $('.filter-mobile').addClass('d-none');
                    $('.button-filter-mobil').removeClass('filter-mobile-open')
                });


                $(document).ready(function (e) {
                    if (window.location.search === "?page=2"){
                        $('.previous').find('.page-link').css('color', '#1C1E20');
                        $('.previous').find('.page-link').css('opacity', '1');
                    }
                });

                $('.cadencier-info').click(function(event){
                    event.stopPropagation();
                    var $infoOpen = $(this).parent().find(".cadencier-info-open");
                
                    var wasOpen = $infoOpen.hasClass('open');
                    $(".cadencier-info-open").removeClass('open').addClass('d-none'); 
                    if(!wasOpen) {
                        $infoOpen.addClass('open').removeClass('d-none');
                    }
                });
                
                $(document).click(function(){
                    $(".cadencier-info-open").removeClass('open').addClass('d-none');
                });
                
                $(".cadencier-info-open").click(function(event){
                    event.stopPropagation();
                });
                
               
                $('.cadencier-info-open .container-info-cadencier').mouseleave(function(){
                    $(this).closest('.cadencier-info-open').addClass('d-none').removeClass('open');
                });
 

                // assortiment picto liste perso
                // $('.picto-list-product').mouseenter(function(){
                //     $(this).find(".bloc-liste-perso").addClass('d-flex').removeClass('d-none');
                // });
                // $( ".bloc-liste-perso").mouseleave(function() {
                //     $(this).addClass('d-none').removeClass('d-flex');
                // });

                $('.picto-list-product').on('click', '.add-prd', function() {
                    $(this).closest('.picto-list-product').find('#dropdownMenuButton').trigger('click');
                });
                
                $('.picto-list-product').click(function() {
                    $(this).find(".bloc-liste-perso").addClass('d-flex').removeClass('d-none');
                    var productIdDiv = $(this).find('.product-id');
                    var productId = parseInt(productIdDiv.text().trim(), 10);
                    var listNameDiv = $(this).find('.list-name');
                    var addPrdButton = $(this).find('.add-prd');
                    loadWishlistsByProduct(productId, listNameDiv, addPrdButton);
                });
                
                $(".bloc-liste-perso").mouseleave(function() {
                    $(this).addClass('d-none').removeClass('d-flex');
                });
                
                function loadWishlistsByProduct(productId, listNameDiv, addPrdButton) {
                    fetch('/wishlist/find-by-product', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ productId: productId })
                    })
                    .then(response => response.json())
                    .then(data => {
                        listNameDiv.empty();
                        
                        const wishlistNames = data.map(wishlist => wishlist.name).join(", ");
                        const displayText = data.length > 0 ? `Ce produit est déjà présent dans la liste : ${wishlistNames}` : 'Ce produit ne fait partie d\'aucune liste personnelle.';
                        listNameDiv.append(`<div>${displayText}</div>`);
                        
                        if (data.length > 0) {
                            addPrdButton.text('Ajouter ce produit à une autre liste existante');
                        } else {
                            addPrdButton.text('Ajouter ce produit à une liste existante');
                        }
                    })
                    .catch(error => console.error('Error:', error));
                }
                

                $('.more-custom-filter-label').click(function(){
                    if ( $('.select-item-label ').hasClass('custom-filter-label-close')){
                        $('.select-item-label').removeClass('custom-filter-label-close').addClass('custom-filter-label-open');
                        $('.more-custom-filter-label').addClass('d-none');
                        $('.less-custom-filter-label').removeClass('d-none');
                    };
                });
                $('.less-custom-filter-label').click(function(){
                    if ( $('.select-item-label ').hasClass('custom-filter-label-open')){
                        $('.select-item-label').removeClass('custom-filter-label-open').addClass('custom-filter-label-close');
                        $('.more-custom-filter-label').removeClass('d-none');
                        $('.less-custom-filter-label').addClass('d-none');
                    };
                });
//

                $('.more-custom-filter-brand').click(function(){
                    if ( $('.select-item-brand ').hasClass('custom-filter-brand-close')){
                        $('.select-item-brand').removeClass('custom-filter-brand-close').addClass('custom-filter-brand-open');
                        $('.more-custom-filter-brand').addClass('d-none');
                        $('.less-custom-filter-brand').removeClass('d-none');
                    };
                });
                $('.less-custom-filter-brand').click(function(){
                    if ( $('.select-item-brand ').hasClass('custom-filter-brand-open')){
                        $('.select-item-brand').removeClass('custom-filter-brand-open').addClass('custom-filter-brand-close');
                        $('.more-custom-filter-brand').removeClass('d-none');
                        $('.less-custom-filter-brand').addClass('d-none');
                    };
                });
//


                $('.more-custom-filter-nutritionnelles').click(function(){
                    if ( $('.select-item-nutritionnelles ').hasClass('custom-filter-nutritionnelles-close')){
                        $('.select-item-nutritionnelles').removeClass('custom-filter-nutritionnelles-close').addClass('custom-filter-nutritionnelles-open');
                        $('.more-custom-filter-nutritionnelles').addClass('d-none');
                        $('.less-custom-filter-nutritionnelles').removeClass('d-none');
                    };
                });
                $('.less-custom-filter-nutritionnelles').click(function(){
                    if ( $('.select-item-nutritionnelles ').hasClass('custom-filter-nutritionnelles-open')){
                        $('.select-item-nutritionnelles').removeClass('custom-filter-nutritionnelles-open').addClass('custom-filter-nutritionnelles-close');
                        $('.more-custom-filter-nutritionnelles').removeClass('d-none');
                        $('.less-custom-filter-nutritionnelles').addClass('d-none');
                    };
                });




            // setTimeout(function(){
            //     $('.article-annule').removeClass('d-none').addClass('d-flex')
            // },8000); 


                if( $('.accordion-result__tag').html() ) {
                    $('.no-filter').addClass('d-none');
                    $('.vos-filtres').removeClass('d-none');
                    $('.reinit-filter').removeClass('d-none').addClass('d-flex');
                };



 


window.addEventListener("load", function(event) {
    // slick carousel
    $('.slider').slick({
      dots: true,
      vertical: true,
      slidesToShow: 8,
      slidesToScroll: 8,
      verticalSwiping: true,
      infinite: false,
    });
  
  
  
  
  $(".slider").on('wheel', (function(e) {
    e.preventDefault();
  
    if (e.originalEvent.deltaY < 0) {
      $(this).slick('slickNext');
    } else {
      $(this).slick('slickPrev');
    }
  }));


  $(".select-redirect-on-change").change(function() {
    window.location.href = $(this).val();
});

  
  });

$('.basket-select').change(function () {
    // pour changer de cart depuis la popup
    let cartId = $(this).val();
    let form = $(this).closest('form');

    $(form).children('input[name="cartId"]').prop('value', cartId)
    form.submit();
    return (cartId === form.children('input[name="cartId"]').prop('value'));
});

// menu pour client n'ayant pas accès aux factures
  $(function() {
    $('.navbar-nav-cust-not-granted-facture ul.navbar-nav').each(function() {
        var $select = $('<select />');

        $(this).find('a').each(function() {
        var $option = $('<option />');
        $option.attr('value', $(this).attr('href')).html($(this).html());
        $select.append($option);
        });

        $(this).replaceWith($select);
    });
});

//function select redirect on change
$(function(){
    // bind change event to select
    $('.navbar-nav-cust-not-granted-facture select').on('change', function () {
        var url = $(this).val(); // get selected value
        if (url) { // require a URL
            window.location = url; // redirect
        }
        return false;
    });
});
// menu pour client n'ayant pas accès aux factures


$(function(){ 

    $('#input-search-invoice').on("keypress", function(event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === "Enter") {
          // Cancel the default action, if needed
          event.preventDefault();
          // Trigger the button element with a click
          const search = $(this).val();

          if(search !== "") {
              window.location = updateQueryStringParameter(window.location.href , 'search', search);
          }
  
        }
      }); 

      $('.order-invoice-choice').on('change', function(){
        window.location = $(this).val();
     });

});

/// espace-client/nouvelle-commande background color 
$(function(){ 
    $(document).ready(function() {
        $("#new-order").click(function() {
            $(".new-command-input").removeClass("new-command-background-grey");
            $(this).parent(".new-command-input").addClass("new-command-background-grey");
            $('form[name="cart"]').addClass("new-command-background-grey");
        });
        $("#existing-order").click(function() {
            $(".new-command-input").removeClass("new-command-background-grey");
            $(this).parent(".new-command-input").addClass("new-command-background-grey");
        });
    });

});

function setFixedHeight() {
    var productDetailsHeight = $('.block-product-details').height(); 
    $('.col-cust').css('height', productDetailsHeight + 'px'); 
}

function checkOffset() {
    var productInfo = $('.div-start-product-info');
    var colCust = $('.col-cust');

    if (productInfo.length > 0) {
        var offsetTop = productInfo.offset().top;

        if (window.scrollY > offsetTop - 10) {
            var newMarginTop = window.scrollY - (offsetTop - 10);
            var colCustHeight = colCust.height(); 
            var colCustBottom = colCust.offset().top + colCustHeight;
            var blockProductInfoHeight = $('.block-product-info').outerHeight();
            var blockProductInfoBottom = offsetTop + newMarginTop + blockProductInfoHeight;

          
            if (blockProductInfoBottom > colCustBottom) {
                newMarginTop = colCustBottom - blockProductInfoHeight - offsetTop + 10;
            }

            $('.block-product-info').css({
                'display': "block",
                'margin-top': newMarginTop + "px"
            });
        } else {
            $('.block-product-info').css('display', "none");
        }
    }
}

$(document).ready(function() {
    setFixedHeight(); 
    $(window).scroll(checkOffset); 
});
$(window).resize(setFixedHeight);

// get insee infos for inscription form
$(function(){ 
       $('form[name="registration_prospect_account"] input').prop("disabled", true);
       $('form[name="registration_prospect_account"] select').prop("disabled", true);
       $('form[name="registration_prospect_account"] textarea').prop("disabled", true);
       $('form[name="registration_prospect_account"] button').prop("disabled", true);
       $('#registration_prospect_account_siretNumber').prop("disabled", false);

       if( $('#registration_prospect_account_siretNumber').val() ) {
        checkSiret();
       }

       $('#search-company-infos').click( function (e) {

        checkSiret();

       });

});

function checkSiret() {

    let ajax = pap.ajax;
    ajax.url = pap.routing.inscriptionCompany;
    let siret = $('#registration_prospect_account_siretNumber').val();

    ajax.data = {'siret' : siret};
    ajax.dataType = 'json';
    ajax.method = 'POST';
    ajax.onSuccess = function (data) {
        $('#siretNumberError').hide();
        $('form[name="registration_prospect_account"] input').prop("disabled", false);
        $('form[name="registration_prospect_account"] select').prop("disabled", false);
        $('form[name="registration_prospect_account"] textarea').prop("disabled", false);
        $('form[name="registration_prospect_account"] button').prop("disabled", false);
        $('#registration_prospect_account_siretNumber').prop("disabled", false);

        
        $('#registration_prospect_account_name').val(data.data.etablissement.uniteLegale.denominationUniteLegale);
        $('#registration_prospect_account_segment').val(data.data.etablissement.uniteLegale.activitePrincipaleUniteLegale);
        $('#registration_prospect_account_adresse').val(`${data.data.etablissement.adresseEtablissement.numeroVoieEtablissement} ${data.data.etablissement.adresseEtablissement.typeVoieEtablissement} ${data.data.etablissement.adresseEtablissement.libelleVoieEtablissement} `);
        $('#registration_prospect_account_postalCode').val(data.data.etablissement.adresseEtablissement.codePostalEtablissement);
        $('#registration_prospect_account_locality').val(data.data.etablissement.adresseEtablissement.libelleCommuneEtablissement);
        
    };

    ajax.onError = function (data) {
        $('#siretNumberError').show();

        $('form[name="registration_prospect_account"] input').prop("disabled", true);
        $('form[name="registration_prospect_account"] select').prop("disabled", true);
        $('form[name="registration_prospect_account"] textarea').prop("disabled", true);
        $('form[name="registration_prospect_account"] button').prop("disabled", true);
        $('#registration_prospect_account_siretNumber').prop("disabled", false);
        
        
        $('#registration_prospect_account_name').val('');
        $('#registration_prospect_account_segment').val('');
        $('#registration_prospect_account_adresse').val('');
        $('#registration_prospect_account_postalCode').val('');
        $('#registration_prospect_account_locality').val('');

    };


   ajax.execute();

}


var updateQueryStringParameter = (uri, key, value) => {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  // modale inscription

$('#inscriptionbutton').on('click', function (e) {
    $('#modalinscription').modal('show', '#' + $(this).attr('id'));
});
$('#inscriptionbuttonmobile').on('click', function (e) {
    $('#modalinscription').modal('show', '#' + $(this).attr('id'));
});

$('#filterbutton').on('click', function (e) {
    $('#modalfilters').modal('show', '#' + $(this).attr('id'));
});

$('.button-modal-order-banner').on('click', function (e) {
    $('#modalorderbanner').modal('show', '#' + $(this).attr('id'));
    e.preventDefault();
});


// menu prospect dropdown

function closeAllDropdowns() {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    for (var i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
    }
}

$(document).ready(function() {
    $('#toggleDropdownOne, #imgDropdownOne').on('click', function (e) {
        e.stopPropagation(); // Prevent the click from bubbling up
        console.log('Dropdown One toggled');
        closeAllDropdowns();
        $('#firstDropdown').toggleClass("show");
    });

    $('#toggleDropdownTwo, #imgDropdownTwo').on('click', function (e) {
        e.stopPropagation(); // Prevent the click from bubbling up
        closeAllDropdowns();
        $('#secondDropdown').toggleClass("show");
    });

    function closeAllDropdowns() {
        $('.dropdown-content').removeClass('show');
    }

    // Close the dropdown menu if the user clicks outside of it
    $(window).on('click', function() {
        closeAllDropdowns();
    });
});
// Ferme le menu déroulant si l'utilisateur clique en dehors
window.onclick = function(event) {
    if (!event.target.matches('.dropbtn')) {
        closeAllDropdowns();
    }
}

$(document).ready(function() {
    $('#accordion .btn').click(function() {
        var icon = $(this).find('.icon');
        icon.toggleClass('rotate-0 rotate-180');
    });
});

window.pap = pap;
// create global $ and jQuery variables
window.jQuery = $;
window.$ = $;
